<section class="cart-section" *ngIf="cart && cart.length > 0">
  <div class="cart-content">
    <div *ngFor="let item of cart; let i = index" class="cart-item form-row flex-wrap align-items-center">
      <div class="col-sm-6 col-md-6 cart-desc-row">
        <div class="form-row">
          <div class="col-md-3 cart-img-wrap">
            <img class="cart-img" [src]="item.prod_image">
          </div>
          <div class="description col-md-9">
            <div class="item-name">{{item.name}} <span class="f14 primary-text font-weight-normal">{{item.price | currency}}</span></div>
            <div class="item-option" *ngFor="let option of item.option">
              - {{option.option_name}} <span class="f14 primary-text">{{option.option_price | currency}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4 col-md-4 cart-qty-row">
        <div class="d-flex align-items-bottom">
          <button mat-mini-fab color="primary" class="mr-3" (click)="removeCartItem(i)" >
            <i class="delete-icon fa fa-trash"></i>
          </button>
          <button mat-mini-fab color="primary" (click)="decreaseQuantity(i)">
            <i class="fa fa-minus"></i>
          </button>
          <div class="text-center mx-3">
            <div>Qty</div>
            <div class="item-quantity">{{item.quantity}}</div>
          </div>
          <button mat-mini-fab color="primary" (click)="increaseQuantity(i)">
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>

      <div class="item-price cart-price-row col-sm-2 col-md-2 text-center">
        <div class="f18 font-weight-bold">{{ item.total | currency}}</div>
        <div class="f12">incl GST</div>
      </div>
    </div>
  </div>
</section>

<section *ngIf="!cart || cart.length == 0">
  No items in cart. <a class="primary-text" [routerLink]="['/product']">Add to cart.</a>
</section>