import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from '../interface/user.interface';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-10px)' }),
        animate('200ms', style({ opacity: 1, transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        animate('200ms', style({ opacity: 0, transform: 'translateX(-10px)' })),
      ]),
    ]),
  ]
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {

  sideNavDisplay: boolean = false;
  unsubscribe$ = new Subject();
  user: User;

  constructor(
    private userService: UserService
  ) { }

  ngOnInit(): void {
  }

  sideNav(): void {
    this.sideNavDisplay = !this.sideNavDisplay;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.userService.user$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        this.user = user;
      });
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
