<div class="page-content">
  <section class="promotion-detail">
    <div class="container-fluid">
      <div class="col-12 mb-4">
        <a class="text-underline primary-text" [routerLink]="['/promotion']"> << Back to Promotions</a>
      </div>

      <div class="section-title">
        <h3>Promotion Details</h3>
      </div>
      
      <div *ngIf="promotion" class="row">
        <div class="col-md-6 matchheight">
          <div class="thumbnail">
            <img [src]="promotion.image_web" [alt]="promotion.name" style="width: 100%;">
          </div>
        </div>
        <div class="col-md-6 matchheight">
          <div class="content">
            <div class="title">{{promotion.name}}
              <span *ngIf="promotion.voucher && promotion.voucher.length > 0 && promotion.voucher[0].value_type === 'percentage'">Discount {{promotion.voucher[0].value | number}}%</span>
              <span *ngIf="promotion.voucher && promotion.voucher.length > 0 && promotion.voucher[0].value_type === 'value'">Discount {{promotion.voucher[0].value | currency}}</span>
            </div>
            <div *ngIf="promotion.voucher && promotion.voucher.length > 0" class="date">
              From {{promotion.voucher[0].start_date | date:'d MMM, Y'}} to {{promotion.voucher[0].end_date | date:'d MMM, Y'}}
            </div>
            <div class="line"></div>
            <div class="desc promo-desc" [innerHTML]="promotion.desc | sanitizeHtml"></div>
            <div *ngIf="promotion.tnc" class="desc promo-desc" [innerHTML]="promotion.tnc | sanitizeHtml"></div>
            <div *ngIf="promotion.voucher && promotion.voucher.length > 0" class="voucher-code">
              <a class="btn-voucher" role="button" (click)="onCollectVoucher(promotion.voucher[0].id)">Claim Voucher<small>{{promotion.voucher[0].name}}</small></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
</div>