import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SanitizeHtmlPipe } from './pipe/sanitizeHtml';
import { TruncatePipe } from './pipe/truncateText';
import { FilterPipe } from './pipe/filterText';
import { SafeUrlPipe } from './pipe/safeUrl';

@NgModule({
  declarations: [
    SanitizeHtmlPipe,
    TruncatePipe,
    FilterPipe,
    SafeUrlPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [ 
    SanitizeHtmlPipe,
    TruncatePipe,
    FilterPipe,
    SafeUrlPipe
  ]
})
export class SharedModule { }
