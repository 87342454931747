<section class="page-content">
  <div class="container-fluid">
    <div class="page-title">
      <h2 *ngIf="!registerValidate">Register</h2>
      <h2 *ngIf="registerValidate">Activation</h2>
    </div>
    <div *ngIf="!registerValidate" class="register-tab">
      <ul class="nav nav-tabs">
        <li [class.active]="activeTab === 'email'">
          <a class="show" [class.active]="activeTab === 'email'" data-toggle="tab" (click)="setActiveTab('email')">Email</a>
        </li>
        <li [class.active]="activeTab === 'mobile'">
          <a data-toggle="tab" [class.active]="activeTab === 'mobile'" (click)="setActiveTab('mobile')">Mobile</a>
        </li>
      </ul>
      
      <div class="tab-content">
        <div *ngIf="activeTab === 'email'" [ngClass]="activeTab==='email'?'show active':'fade'" id="email" class="tab-pane">
          <div class="form-register-email">
            <form [formGroup]="form">
              <div class="row">
                <ng-container *ngIf="errorMessage && activeTab === 'email'">
                  <div class="col-sm-12">
                    <div class="alert alert-danger">{{ errorMessage }}</div>
                  </div>
                </ng-container>
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field class="w-100" appearance="legacy">
                      <mat-label>First Name
                        <span *ngIf="form.controls.firstName.touched && form.controls.firstName.invalid">
                          <i *ngIf="form.controls.firstName.errors.required" class="fa fa-exclamation-circle text-alert"></i>
                        </span>
                      </mat-label>
                      <input matInput type="text" name="fname" formControlName="firstName">
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field class="w-100" appearance="legacy">
                      <mat-label>Last Name
                        <span *ngIf="form.controls.lastName.touched && form.controls.lastName.invalid">
                          <i *ngIf="form.controls.lastName.errors.required" class="fa fa-exclamation-circle text-alert"></i>
                        </span>
                      </mat-label>
                      <input matInput type="text" name="lname" formControlName="lastName">
                    </mat-form-field>
                  </div>
                </div>
                
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field class="w-100" appearance="legacy">
                      <mat-label>Email
                        <span *ngIf="form.controls.emailAddress.touched && form.controls.emailAddress.invalid">
                          <i *ngIf="form.controls.emailAddress.errors.required" class="fa fa-exclamation-circle text-alert"></i>
                        </span>
                      </mat-label>
                      <input matInput type="email" formControlName="emailAddress" id="email" name="email">
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group c-left">
                    <mat-form-field class="w-100" appearance="legacy">
                      <mat-label>Code</mat-label>
                      <input matInput
                        [matAutocomplete]="auto"
                        class="form-control" 
                        type="text"
                        id="code" 
                        name="code"
                        [formControl]="countryCtrl"
                        class="country-select">
                      <mat-autocomplete #auto="matAutocomplete" [panelWidth]="300">
                        <mat-option *ngFor="let country of filteredCountries | async" [value]="country.phonecode" (onSelectionChange)="selectCountry(country)">
                          <span>{{country.phonecode}} ({{country.name}})</span>
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div class="form-group c-right">
                    <mat-form-field class="w-100" appearance="legacy">
                      <mat-label>Mobile No
                        <span *ngIf="form.controls.mobileNo.touched && form.controls.mobileNo.invalid">
                          <i *ngIf="form.controls.mobileNo.errors.required" class="fa fa-exclamation-circle text-alert"></i>
                        </span>
                      </mat-label>
                      <input matInput type="text"  formControlName="mobileNo" id="mobileno" name="mobileno">
                    </mat-form-field>
                  </div>
                </div>
                
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field class="w-100" appearance="legacy">
                      <mat-label>Postcode
                        <span *ngIf="form.controls.postcode.touched && form.controls.postcode.invalid">
                          <i *ngIf="form.controls.postcode.errors.required" class="fa fa-exclamation-circle text-alert"></i>
                        </span>
                      </mat-label>
                      <input matInput type="text" formControlName="postcode" id="postcode" name="postcode">
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group position-relative">
                    <mat-form-field class="w-100" appearance="legacy">
                      <mat-label>Password
                        <span *ngIf="form.controls.password.touched && form.controls.password.invalid">
                          <i *ngIf="form.controls.password.errors.required" class="fa fa-exclamation-circle text-alert"></i>
                        </span>
                      </mat-label>
                      <input matInput type="password" formControlName="password"name="password" [type]="showPassword ? 'text' : 'password'">
                      <i class="show-password-icon" [class]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="onClickShowPassword()"></i>
                    </mat-form-field>
                  </div>
                </div>
                
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field class="w-100" appearance="legacy">
                      <mat-label>Confirm Password
                        <span *ngIf="form.controls.confirmPassword.touched && form.controls.confirmPassword.invalid">
                          <i *ngIf="form.controls.confirmPassword.errors.required" class="fa fa-exclamation-circle text-alert"></i>
                        </span>
                      </mat-label>
                      <input matInput type="password" formControlName="confirmPassword" id="confirmPassword" name="confirmPassword">
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field class="w-100" appearance="legacy">
                      <mat-label>Referrel Code (optional)</mat-label>
                      <input matInput type="text" formControlName="refCode" id="refCode" name="refCode">
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-check mb-4 px-0">
                    <mat-checkbox class="example-margin register-checkbox" [(ngModel)]="tncCheck" [ngModelOptions]="{standalone: true}">
                      <div class="text-wrap">
                        By creating an account, you are agreeing to Sushi Izu's terms and conditions which can be accessible 
                        <a class="text-underline" role="button" (click)="onTermPopup()">here.</a>
                      </div>
                    </mat-checkbox>
                  </div>
                </div>

                <div class="col-sm-12">
                  <re-captcha (resolved)="resolved($event)" [siteKey]="siteKey" required></re-captcha>
                </div>

                <div class="col-sm-12">
                  <div class="form-group submitform mt-4">
                    <button type="submit" class="btn btn-register" [disabled]="!tncCheck || isLoading" (click)="submitRegister()">Register</button>
                  </div>
                </div>
                <div class="col-sm-12 text-center mb-4">
                  <button class="btn btn-facebook" [disabled]="isLoading" (click)="submitFacebookRegister()">Continue with Facebook</button>
                </div>
                <div class="col-sm-12">
                  <p class="text-center">Already have an account? <a [class.disabled]="isLoading" [routerLink]="['/login']">Login</a></p>
                </div>
              </div><!-- end row-->
            </form>
          </div>
        </div><!-- end email form -->
        
        <div *ngIf="activeTab === 'mobile'" [ngClass]="activeTab==='mobile'?'show active':'fade'" id="mobile" class="tab-pane">
          <div class="form-register-mobile">
            <form [formGroup]="form">
              <div class="row">
                <ng-container *ngIf="errorMessage && activeTab === 'mobile'">
                  <div class="col-sm-12">
                    <div class="alert alert-danger">{{ errorMessage }}</div>
                  </div>
                </ng-container>
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field class="w-100" appearance="legacy">
                      <mat-label>First Name
                        <span *ngIf="form.controls.firstName.touched && form.controls.firstName.invalid">
                          <i *ngIf="form.controls.firstName.errors.required" class="fa fa-exclamation-circle text-alert"></i>
                        </span>
                      </mat-label>
                      <input matInput type="text" name="fname" formControlName="firstName">
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field class="w-100" appearance="legacy">
                      <mat-label>Last Name
                        <span *ngIf="form.controls.lastName.touched && form.controls.lastName.invalid">
                          <i *ngIf="form.controls.lastName.errors.required" class="fa fa-exclamation-circle text-alert"></i>
                        </span>
                      </mat-label>
                      <input matInput type="text" name="lname" formControlName="lastName">
                    </mat-form-field>
                  </div>
                </div>
                
                <div class="col-sm-6">
                  <div class="form-group c-left">
                    <mat-form-field class="w-100" appearance="legacy">
                      <mat-label>Code</mat-label>
                      <input matInput
                        [matAutocomplete]="auto"
                        class="form-control" 
                        type="text"
                        id="code" 
                        name="code"
                        [formControl]="countryCtrl"
                        class="country-select">
                      <mat-autocomplete #auto="matAutocomplete" [panelWidth]="300">
                        <mat-option *ngFor="let country of filteredCountries | async" [value]="country.phonecode" (onSelectionChange)="selectCountry(country)">
                          <span>{{country.phonecode}} ({{country.name}})</span>
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div class="form-group c-right">
                    <mat-form-field class="w-100" appearance="legacy">
                      <mat-label>Mobile No
                        <span *ngIf="form.controls.mobileNo.touched && form.controls.mobileNo.invalid">
                          <i *ngIf="form.controls.mobileNo.errors.required" class="fa fa-exclamation-circle text-alert"></i>
                        </span>
                      </mat-label>
                      <input matInput type="text"  formControlName="mobileNo" id="mobileno" name="mobileno">
                    </mat-form-field>
                  </div>
                </div>
                
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field class="w-100" appearance="legacy">
                      <mat-label>Postcode
                        <span *ngIf="form.controls.postcode.touched && form.controls.postcode.invalid">
                          <i *ngIf="form.controls.postcode.errors.required" class="fa fa-exclamation-circle text-alert"></i>
                        </span>
                      </mat-label>
                      <input matInput type="text" formControlName="postcode" id="postcode" name="postcode">
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group position-relative">
                    <mat-form-field class="w-100" appearance="legacy">
                      <mat-label>Password
                        <span *ngIf="form.controls.password.touched && form.controls.password.invalid">
                          <i *ngIf="form.controls.password.errors.required" class="fa fa-exclamation-circle text-alert"></i>
                        </span>
                      </mat-label>
                      <input matInput type="password" formControlName="password"name="password" [type]="showPassword ? 'text' : 'password'">
                      <i class="show-password-icon" [class]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="onClickShowPassword()"></i>
                    </mat-form-field>
                  </div>
                </div>
                
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field class="w-100" appearance="legacy">
                      <mat-label>Confirm Password
                        <span *ngIf="form.controls.confirmPassword.touched && form.controls.confirmPassword.invalid">
                          <i *ngIf="form.controls.confirmPassword.errors.required" class="fa fa-exclamation-circle text-alert"></i>
                        </span>
                      </mat-label>
                      <input matInput type="password" formControlName="confirmPassword" id="confirmPassword" name="confirmPassword">
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field class="w-100" appearance="legacy">
                      <mat-label>Referrel Code (optional)</mat-label>
                      <input matInput type="text" formControlName="refCode" id="refCode" name="refCode">
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-check mb-4 px-0">
                    <mat-checkbox class="example-margin register-checkbox" [(ngModel)]="tncCheck" [ngModelOptions]="{standalone: true}">
                      <div class="text-wrap">
                        By creating an account, you are agreeing to Sushi Izu's terms and conditions which can be accessible 
                        <a class="text-underline" role="button" (click)="onTermPopup()">here.</a>
                      </div>
                    </mat-checkbox>
                  </div>
                </div>

                <div class="col-sm-12">
                  <re-captcha (resolved)="resolved($event)" [siteKey]="siteKey" required></re-captcha>
                </div>
              
                <div class="col-sm-12">
                  <div class="form-group submitform mt-4">
                    <button type="submit" class="btn btn-register" [disabled]="!tncCheck || isLoading" (click)="submitRegister()">Register</button>
                  </div>
                </div>
                <div class="col-sm-12 text-center mb-4">
                  <button class="btn btn-facebook"[disabled]="isLoading" (click)="submitFacebookRegister()">Continue with Facebook</button>
                </div>
                <div class="col-sm-12">
                  <p class="text-center">Already have an account? <a [class.disabled]="isLoading" [routerLink]="['/login']">Login</a></p>
                </div>
              </div><!-- end row-->
            </form>
          </div>
        </div><!-- end mobile form -->
      </div><!-- end tab-content -->
    </div><!-- end register-tab-->
    
    <div *ngIf="registerValidate" class="form-register-email">
      <p>A verification code has been sent to your <span *ngIf="activeTab == 'mobile'">mobile number</span><span *ngIf="activeTab == 'email'">email address</span>.</p>
      <form [formGroup]="tacCode">
        <div class="row">
          <div class="col-sm-12 mx-auto">
            <div class="form-group">
              <mat-form-field class="w-100" appearance="legacy">
                <mat-label>Please enter your 6 digits code here</mat-label>
                <input matInput type="text" maxlength="6" formControlName="code">
              </mat-form-field>
            </div>
          </div>
          <div class="col-sm-12">
            <button class="btn btn-tac mr-3"[disabled]="isLoading" (click)="onSubmitTac()">Verify</button>
            <button class="btn btn-tac" [disabled]="isLoading" (click)="onResendTac()">Resend Code</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>