import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from '../interface/user.interface';
import { CommonService } from '../service/common.service';
import { MenuService } from '../service/menu.service';
import { UserService } from '../service/user.service';
import { Location } from '../interface/common.interface';
import { Store } from '../interface/menu.interface';

@Component({
  selector: 'app-store-detail',
  templateUrl: './store-detail.component.html',
  styleUrls: ['./store-detail.component.css']
})
export class StoreDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  storeName: string;
  store: Store;
  location: Location;
  user: User;

  private unsubscribe$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private menuService: MenuService,
    private commonService: CommonService,
    private userService: UserService
  ) {
    this.commonService.updateTitle('Store');
    this.storeName = this.route.snapshot.params.name;
   }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.userService.getUserLocationPayload().then((postData) => {
        this.menuService.stores$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((data) => {
          if(!data) {
            this.menuService.getStore(postData).subscribe((response) => {
              if(!response.success) {
                return;
              }
              this.menuService.setStore(response.data);
            });
            return;
          }
          this.store = data.filter(store => this.commonService.dashCase(store.name) == this.storeName)[0];
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
