import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgotpassword-popup',
  templateUrl: './forgotpassword-popup.component.html',
  styleUrls: ['./forgotpassword-popup.component.css']
})
export class ForgotpasswordPopupComponent implements OnInit {

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ForgotpasswordPopupComponent>
  ) { }
  
  ngOnInit(): void {
  }

  goToChangePassword(): void {
    this.router.navigate(['/dashboard/change-password']);
    this.dialogRef.close();
  }
}
