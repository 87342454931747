import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { campaignParam } from '../interface/menu.interface';
import { OrderSuccess } from '../interface/order.interface';
import { CampaignPopupComponent } from '../popup/campaign-popup/campaign-popup.component';
import { CommonService } from '../service/common.service';
import { MenuService } from '../service/menu.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css']
})
export class PaymentSuccessComponent implements OnInit {

  ordered: any;
  orderId: any;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private menuService: MenuService,
    private dialog: MatDialog,
  ) { 
    this.orderId = this.route.snapshot.params.orderId;
    if(!this.orderId) {
      this.router.navigate(['dashboard/purchase']);
      return;
    }
  }

  ngOnInit(): void {
    this.getOrder();
  }

  private getOrder(): void {
    this.userService.paymentOrder$
    .pipe(take(1))
    .subscribe((data) => {
      if(!data) {
        this.router.navigate(['dashboard/purchase']);
        return;
      }
      if(data.orderno && data.orderno != this.orderId) {
        this.router.navigate(['dashboard/purchase']);
        return;
      }
      this.ordered = data;

      const qrDialog = this.generateQr(data);

      const user = this.userService.getUser();
      const storeId = this.userService.getStoreId();

      qrDialog.subscribe((data) => {
        const campaignData: campaignParam = {
          total_value: this.ordered.total_value,
          type: 'spend',
          storeID: storeId,
          session: user.session,
          deviceID: user.deviceID,
          campaign_type: 'spend'
        }
        this.checkCampaign(campaignData);
      })
    });
  }

  private generateQr(data: OrderSuccess): Observable<any> {
    const orderNo = data.orderno;
    const code = data.collect_code;
    const qrDialog = this.commonService.generateQr(`Order No. ${orderNo}`, 'Please present this QR code at the store to pick up your order. Thank you. \n\n You may also access this QR code in your Purchase History section of the app.', code);
    return qrDialog;
  }

  private checkCampaign(campaignData: campaignParam): void {
    this.menuService.getCampaign(campaignData).subscribe((response) => {
      if(response && response.data) {
        const campaign = response.data[0];
        this.spendCampaign(campaignData, campaign);
        return;
      }
    });
  }

  private spendCampaign(campaignData:campaignParam, campaign): void {
    const dialogRef = this.dialog.open(CampaignPopupComponent, {
      width: '500px',
      data: campaign
    });
    dialogRef.afterClosed().subscribe((data) => {
      this.userService.updatePopup({signup: true});
      if(data) {
        let postData = { ...campaignData, ...{campaign_id: campaign.id}};
        this.menuService.updateCampaign(postData).subscribe((response) => {
          return;
        });
      }
    });
  }
}
