import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CartItem } from '../interface/cart.interface';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private cart: BehaviorSubject<CartItem[]> = new BehaviorSubject([]);
  cart$: Observable<CartItem[]> = this.cart.asObservable();

  constructor() { }

  storeCart(): void {
    const cartItems = this.cart.value;
    localStorage.setItem('sushiizu_cart', JSON.stringify(cartItems));
  }

  getCart(): void {
    const cartItems = JSON.parse(localStorage.getItem('sushiizu_cart'));
    if(cartItems) {
      this.cart.next(cartItems);
    }
  }

  getReadyCart(): CartItem[] {
    const cartItems = this.cart.value;
    return cartItems.map(({prod_image, remark, total, ...keepAttrs}) => keepAttrs);
  }

  handleCartItem(newItem: CartItem): void {
    let found = this.findCartItem(newItem);
    if(found === -1) {
      this.addCartItem(newItem)
    } else {
      this.updateCartItem(found, newItem);
    }
    this.storeCart();
  }

  updateCartItem(index: number, newItem: CartItem): void {
    let cartItems = this.cart.value;
    cartItems[index].quantity += newItem.quantity;
    cartItems[index].total += newItem.total;
    this.cart.next(cartItems);
  }

  findCartItem(itemToFind: CartItem): number {
    let cartItems = this.cart.value;
    let foundIndex = -1;
    if(!cartItems || (cartItems && cartItems.length ==0)) {
      return foundIndex;
    }

    cartItems.find((item, index) => {
      if(item.prod_id === itemToFind.prod_id && item.option.length == itemToFind.option.length) {
        if(itemToFind.option.length === 0) {
          foundIndex = index;
        }
        let differentOption = false;
        item.option.forEach((option) => {
          let exist = itemToFind.option.filter((toFindOption) => toFindOption.option_id === option.option_id);
          if(exist.length === 0) {
            differentOption = true;
          }
        })
        if(!differentOption) {
          foundIndex = index;
        }
      }
    });
    return foundIndex;
  }

  addCartItem(newItem: CartItem): void {
    let updatedCart = [...this.cart.value, newItem];
    this.cart.next(updatedCart);
  }

  removeCartItem(index: number): void {
    let updatedCart = this.cart.value;
    updatedCart.splice(index, 1);
    this.cart.next(updatedCart);
    this.storeCart();
  }

  calculateItemPrice(item: CartItem): number {
    let optionSum = 0;
    if(item) {
      item.option.forEach(option => optionSum += +option.option_price);
    }
    let total = (+item.price + optionSum) * item.quantity;
    return total;
  }

  calculateTotalCart(cart: CartItem[]): number {
    let total = 0;
    if(cart && cart.length > 0) {
      cart.forEach(item => total += +item.total);
    }
    return total;
  }

  cartLength(): number {
    const cartItems = this.cart.value;
    return cartItems.length;
  }

  destroy(): void {
    this.cart.next([]);
    this.storeCart();
  }
}
