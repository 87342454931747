<button class="close" mat-icon-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>Promotions</h1>
<div mat-dialog-content class="mb-4" *ngIf="promotions">
  <owl-carousel [options]="promotionOption" [carouselClasses]="['owl-theme', 'owl-banner']" #owlElement>
    <div class="item" *ngFor="let promo of promotions">
      <img class="promotion-popup-img" [src]="promo.image_web" [alt]="promo.name" [title]="promo.name">
      <div class="mt-3">
        <div class="desc promo-desc font-weight-bold mb-2" [innerHTML]="promo.name | sanitizeHtml"></div>
        <div class="desc promo-desc text-justify" [innerHTML]="promo.desc | sanitizeHtml"></div>
      </div>
      <!-- <div class="my-4">
        <button mat-raised-button color="primary" (click)="onCollectVoucher(promo.voucher[0].id)">Redeem</button>
      </div> -->
    </div>
  </owl-carousel>
</div>

<div mat-dialog-actions align="center">
  <button mat-raised-button color="primary" (click)="onViewAllPromotion()">View All Promotions</button>
</div>