<div class="page-content">
  <section class="store">
    <div class="container-fluid">
      <div class="section-title">
        <h3>Stores</h3>
      </div>
      <div class="selected-store mb-3">
        <i class="fas fa-store"></i> Selected Store : <span *ngIf="selectedStore">{{selectedStore.name}}</span>
      </div>
      
      <div class="form-group mb-5 d-flex align-items-center">
        <mat-icon>search</mat-icon>
        <input type="search" class="form-control store-search" id="search-text" aria-describedby="search-text" 
          [(ngModel)]="searchText" placeholder="Search store">
      </div>
      
      <div class="row" *ngIf="storeList">
        <div class="col-lg-3 col-md-6 mb-30" *ngFor="let store of storeList | filterStore: searchText">
          <div class="box">
            <a [routerLink]="['/store', slug(store.name)]">
              <div class="d-flex justify-content-between mb-4">
                <div class="name f18 font-weight-medium">{{store.name}}</div>
                <div class="range" *ngIf="checklocationpermission">{{store.distance}}</div>
              </div>
            </a>
            <div class="store-address">
              <div class="icon-text"><i class="fas fa-store"></i></div>
              <div class="text-left">{{store.address}}</div>
            </div>
            <div class="store-phone">
              <div class="icon-text"><i class="fas fa-mobile-alt"></i></div>
              <div>{{store.phone}}</div>
            </div>
            <div class="store-email" *ngIf="store.email">
              <div class="icon-text"><i class="far fa-envelope"></i></div>
              <div>{{store.email ? store.email : '-'}}</div>
            </div>
            <div class="text-center btn-bottom-wrap">
              <a [routerLink]="['/store', slug(store.name)]" class="mr-3">View</a>
              <button class="btn-select mx-auto" *ngIf="!selectedStore" (click)="selectStore(store)">
                <span class="text-center">Select</span>
              </button>
              <button class="btn-select mx-auto" *ngIf="selectedStore" [ngClass]="selectedStore.name === store.name ? 'store-selected' : ''" (click)="selectStore(store)">
                <span class="text-center" *ngIf="selectedStore.name !== store.name">Select</span>
                <span class="text-center" *ngIf="selectedStore.name === store.name">Selected</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <!-- ghost element -->
      <ng-template [ngIf]="!storeList">
        <div class="row ghost-ui">
          <div class="col-lg-3 col-md-6 mb-30" *ngFor="let item of [0, 1, 2, 3]">
            <div class="box">
              <div class="d-flex justify-content-between mb-4">
                <div class="name shimmer"></div>
                <div class="range shimmer"></div>
              </div>
              <div class="store-address shimmer"></div>
              <div class="store-phone shimmer"></div>
              <div class="store-email shimmer"></div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </section>
</div>
