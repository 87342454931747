<button class="close" mat-icon-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<div mat-dialog-content class="pt-4" *ngIf="selectedStore">
  <div class="text-center">
    <p class="font-weight-bold f18 mb-2">Selected Store: </p>
    <p class="font-weight-bold f24 mb-0">{{selectedStore.name}}</p>
    <p class="font-weight-bold f14" *ngIf="locationpermission">({{selectedStore.distance}} away)</p>
    <p class="mt-3">This is your selected store. If you wish to change, please click Change Store, otherwise click Continue</p>
  </div>
</div>

<div mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close="true">Change Store</button>
  <button mat-raised-button color="primary" mat-dialog-close>Continue</button>
</div>