import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  constructor(
    private commonService: CommonService
  ) { 
    this.commonService.updateTitle('404');
  }

  ngOnInit(): void {
  }

}
