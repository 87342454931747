<button class="close" mat-icon-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>
<h1 mat-dialog-title>Payment</h1>

<ng-template [ngIf]="isSqFormLoading">
  Loading ...
</ng-template>

<div [class.visible-hide]="isSqFormLoading">
  <form id="nonce-form" novalidate>
    <div mat-dialog-content class="position-relative">
      <div class="text-center mb-5">
        <img class="mw-200px" src="assets/images/square.png">
      </div>
      <div id="card-container"></div>
    </div>
    <div mat-dialog-actions align="center" class="pt-2 pb-4">
      <button class="btn btn-primary-round" [disabled]="isLoading" (click)="handlePaymentMethodSubmission($event)">Pay With Card</button>
      <!-- <button mat-raised-button color="primary" class="btn btn-payment" (click)="testorder()">Test Order</button> -->
    </div>
  </form>
</div>