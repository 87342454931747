import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CartItem } from '../interface/cart.interface';
import { PromotionVoucher } from '../interface/menu.interface';
import { CartService } from '../service/cart.service';
import { CommonService } from '../service/common.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit, AfterViewInit, OnDestroy {

  cart: CartItem[];
  private unsubscribe$ = new Subject();

  constructor(
    private cartService: CartService,
    private userService: UserService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.cartService.getCart();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.cartService.cart$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if(!data) {
          return;
        }
        this.cart = data;
      });
    });
  }

  removeCartItem(itemIndex: number): void {
    if(this.userService.isVoucherSelected()) {
      let dialogResponse = this.commonService.actionDialog('Alert', 'Changing quantity or removing a product will require you to apply a voucher again.', 'Proceed');
      dialogResponse.subscribe(data => {
        if(data) {
          this.userService.removeVoucher();
          this.cartService.removeCartItem(itemIndex);
        }
      });
      return;
    }
    this.cartService.removeCartItem(itemIndex);
  }

  increaseQuantity(itemIndex: number): void {
    if(this.userService.isVoucherSelected()) {
      let dialogResponse = this.commonService.actionDialog('Alert', 'Changing quantity or removing a product will require you to apply a voucher again.', 'Proceed');
      dialogResponse.subscribe(data => {
        if(data) {
          this.userService.removeVoucher();
          this.cart[itemIndex].quantity += 1;
          this.cartService.storeCart();
          this.updateCartPrice(itemIndex);
        }
      });
      return;
    }
    this.cart[itemIndex].quantity += 1;
    this.cartService.storeCart();
    this.updateCartPrice(itemIndex);
  }

  decreaseQuantity(itemIndex: number): void {
    if(this.userService.isVoucherSelected()) {
      let dialogResponse = this.commonService.actionDialog('Alert', 'Changing quantity or removing a product will require you to apply a voucher again.', 'Proceed');
      dialogResponse.subscribe(data => {
        if(data) {
          this.userService.removeVoucher();
          let currentQuantity = this.cart[itemIndex].quantity;
          if(--currentQuantity > 0) {
            this.cart[itemIndex].quantity -= 1;
          }
          this.updateCartPrice(itemIndex);
        }
      });
      return;
    }
    let currentQuantity = this.cart[itemIndex].quantity;
    if(--currentQuantity > 0) {
      this.cart[itemIndex].quantity -= 1;
    }
    this.updateCartPrice(itemIndex);
  }

  private updateCartPrice(itemIndex: number): void {
    const item = this.cart[itemIndex];
    let updatedTotal = this.cartService.calculateItemPrice(item);
    this.cart[itemIndex].total = updatedTotal;
    this.cartService.storeCart();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
