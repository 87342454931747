<header class="section-header section-container section-row" role="banner">
  <div class="section-wrapper">
    <div class="menu-bars-container column-3">
      <mat-icon class="menu-bars-icon" *ngIf="!sideNavDisplay" (click)="sideNav()">menu</mat-icon>
      <mat-icon class="menu-bars-icon" *ngIf="sideNavDisplay" (click)="sideNav()">close</mat-icon>
      <!-- <i class="menu-bars-icon fa" [ngClass]="sideNavDisplay ? 'fa-times' : 'fa-bars'" (click)="sideNav()"></i> -->
    </div>
    <div class="logo column-3">
      <a href="https://www.sushiizu.com.au">
        <img src="assets/images/sushi-izu-logo.png" alt="Logo" class="logo-img">
      </a>
    </div>
    <div class="order-online column-3 d-flex align-items-center justify-content-end" role="navigation">
      <!-- <a class="order-online-button mx-3" [routerLink]="['.']">
        <span class="ordertext">Order Online</span>
        <span class="ordertext-mobile">Order</span>
      </a> -->
      <a *ngIf="!user || (user && !user.loggedin)" [routerLink]="['/login']" class="mr-2 text-white">Login</a>
      <a *ngIf="user && user.loggedin" [routerLink]="['/dashboard/purchase']" class="mr-2 text-white">
        <div class="d-flex align-items-center">
          <img class="avatar-img mr-2" [src]="user.avatar" alt="profile-image">
          <div class="username-text">Hi, {{user.firstname}}</div>
        </div>
      </a>
      <a [routerLink]="['/checkout']" mat-icon-button><i class="fa fa-shopping-cart" alt="cart"></i></a>
    </div>
  </div>
  <nav class="nav-menu" *ngIf="sideNavDisplay" @fadeSlideInOut>
    <div class="menu-navigation-menu-container">
      <ul id="menu-navigation-menu" class="menu px-0">
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-7 current_page_item menu-item-31"><a href="https://www.sushiizu.com.au/">Home</a></li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-25"><a href="https://www.sushiizu.com.au/about/">About</a></li>
        <!-- <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-25">
          <a class="d-flex align-items-center" routerLink='.'>Order Online<mat-icon>expand_more</mat-icon></a>
          <ul class="sub-menu list-unstyled">
            <li class="sub-sidebar-brand ml-4"><a [routerLink]="['/product']">Menu Selection</a></li>
            <li class="sub-sidebar-brand ml-4"><a [routerLink]="['/store']">Store Selection</a></li>
          </ul>
        </li> -->
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-144"><a href="https://www.sushiizu.com.au/menu/">Menu</a></li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-153"><a href="https://www.sushiizu.com.au/our-stores/">Stores</a></li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-28"><a href="https://www.sushiizu.com.au/pop-up-bar/">Pop Up Bar</a></li>
        <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-512"><a target="_blank" href="http://www.afcsushi.com.au">Franchises</a></li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-30"><a href="https://www.sushiizu.com.au/terms-and-privacy/">Terms &amp; Privacy</a></li>
      </ul>
    </div>
    <div class="social-media">
      <a target="_blank" class="soc-media-links" href="https://www.facebook.com/sushiizu/">
        <img class="soc-media-icons" src="assets/images/soc-med-fb-wh.png">
      </a>
      <a target="_blank" class="soc-media-links" href="https://www.instagram.com/sushiizuaustralia/">
        <img class="soc-media-icons" src="assets/images/soc-med-ig-wh.png">
      </a>
    </div>
  </nav>
</header>