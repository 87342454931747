<section class="page-content">
  <div class="container-fluid">
    
    <div class="pg-login">
      <div class="page-title">
        <h2>Forgot password</h2>
      </div>
      <div class="register-tab">
        <div class="tab-content">
          <div class="form-forgot-password">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group position-relative">
                  <mat-form-field class="w-100" appearance="legacy">
                    <mat-label>Email Or Mobile
                      <span *ngIf="errorRequired || errorMessage">
                        <i class="fa fa-exclamation-circle text-alert"></i>
                      </span>
                    </mat-label>
                    <input matInput [(ngModel)]="input" type="text" name="emailOrMobileInput">
                  </mat-form-field>
                  <span *ngIf="errorMessage">
                    <div class="text-alert">{{ errorMessage }}</div>
                  </span>
                </div>
              </div>
              <div class="col-sm-12 d-flex align-items-center">
                <button class="btn btn-forgot mr-4" (click)="submit()">Forgot Password</button>
                <div><a [routerLink]="['/login']">Go Back</a></div>
              </div>
            </div>
          </div>
        </div><!-- end tab-content -->
      </div>
    </div>
  </div>
</section>
