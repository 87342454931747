<button class="close" mat-icon-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>Select Voucher</h1>
<div mat-dialog-content>
  <div *ngIf="vouchers">
    <div class="voucher-item" *ngFor="let voucher of vouchers">
      <div class="voucher-img col-md-2 col-sm-4 text-center">
        <img [src]="voucher.image">
        <div class="validity-wrap">
          <span class="validity f12 valid mb-3">
            <span *ngIf="voucher.is_match_order == 1">Valid</span>
            <span *ngIf="voucher.is_match_order == 0">Invalid</span>
          </span>
        </div>
      </div>
      <div class="voucher-content col-md-10 col-sm-8">
        <div class="voucher-name">{{voucher.name}}
          <!-- <span *ngIf="voucher.value && voucher.valueType === 'percentage'">({{voucher.value | number}}% off)</span>
          <span *ngIf="voucher.value && voucher.valueType === 'value'">({{voucher.value | currency}} off)</span> -->
        </div>
        <div class="voucher-code mb-2">CODE: {{voucher.code}}</div>
        <div class="voucher-desc" [innerHTML]="voucher.desc | sanitizeHtml"></div>
        <div class="voucher-desc" [innerHTML]="voucher.tnc | sanitizeHtml"></div>
        <div *ngIf="voucher.is_match_order == 0 && voucher.is_match_msg" class="text-alert f14">{{voucher.is_match_msg}}</div>
        <button class="btn btn-redeem mt-2" 
          (click)="selectVoucher(voucher)" 
          [disabled]="(payload.selectedVoucher && payload.selectedVoucher.code === voucher.code) || voucher.is_match_order == 0">
          <span *ngIf="!payload.selectedVoucher || (payload.selectedVoucher && payload.selectedVoucher.code !== voucher.code)">Apply to Cart</span>
          <span *ngIf="payload.selectedVoucher && payload.selectedVoucher.code === voucher.code">Applied</span>
        </button>
      </div>
    </div>
  </div>

  <ng-template [ngIf]="errorMessage">
    <span>{{errorMessage}}</span>
  </ng-template>

  <ng-template [ngIf]="!errorMessage && !vouchers">
    <span>Loading ...</span>
  </ng-template>
</div>