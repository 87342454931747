<button class="close" mat-icon-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title *ngIf="title">{{title}}</h1>
<div mat-dialog-content>
  <ng-template [ngIf]="message">
    <span>{{message}}</span>
  </ng-template>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" mat-dialog-close>OK</button>
</div>