import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { DialogPopupComponent } from '../popup/dialog-popup/dialog-popup.component';
import { Location } from '../interface/common.interface';
import { QrPopupComponent } from '../popup/qr-popup/qr-popup.component';
import { Router } from '@angular/router';
import { AlertPopupComponent } from '../popup/alert-popup/alert-popup.component';
import { Observable } from 'rxjs';
import { PromotionVoucher } from '../interface/menu.interface';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private titleService: Title,
    public dialog: MatDialog,
    private router: Router
  ) { }

  updateTitle(title: string = ''): void {
    this.titleService.setTitle(`${title} - ${environment.appName}`);
  }

  dashCase(str: string): string {
    let removedSpecialChars = str.replace(/[^a-zA-Z0-9 ]/g, '');
    return removedSpecialChars.replace(/\s+/g, '-').toLowerCase();
  }

  getLocation(): Promise<Location> {
    return new Promise((resolve) => {
      // navigator.geolocation.watchPosition
      navigator.geolocation.getCurrentPosition(resp => {
        resolve({lon: resp.coords.longitude, lat: resp.coords.latitude});
      },
      err => {
        resolve({lon: null, lat: null})
      });
    });
  }
  
  toDate(dateStr: any): Date {
    // dateExpiry: "31-01-2021" (dd-mm-yyy)
    const [day, month, year] = dateStr.split("-")
    return new Date(year, month - 1, day)
  }

  formatDate(date: Date): string {
    let year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    // to format (yyyy-mm-dd)
    return year + '-' + month + '-' + day;
  }

  alertDialog(title: string, message: string, actionLogin: boolean = false): void {
    const dialogRef = this.dialog.open(DialogPopupComponent, {
      width: '400px',
      data: {
        message: message,
        title: title
      }
    });

    if(actionLogin) {
      dialogRef.afterClosed().subscribe(() => {
        this.router.navigate(['/login']);
      })
    }
  }

  actionDialog(title: string, message: string, action: string): Observable<any> {
    const dialogRef = this.dialog.open(AlertPopupComponent, {
      width: '400px',
      data: {
        message: message,
        title: title,
        action: action
      }
    });
    return (dialogRef.afterClosed());
  }

  generateQr(title: string, message: string, code: string, voucher?: PromotionVoucher): Observable<any> {
    const dialogRef = this.dialog.open(QrPopupComponent, {
      width: '400px',
      data: {
        message: message,
        title: title,
        code: code,
        voucher: voucher
      }
    });
    return (dialogRef.afterClosed());
  }
}
