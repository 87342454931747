import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductComponent } from './product/product.component';
import { PromotionDetailComponent } from './promotion-detail/promotion-detail.component';
import { PromotionComponent } from './promotion/promotion.component';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { StoreDetailComponent } from './store-detail/store-detail.component';
import { StoreComponent } from './store/store.component';
import { TermsComponent } from './terms/terms.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'promotion',
    component: PromotionComponent,
  },
  {
    path: 'promotion/:name',
    component: PromotionDetailComponent,
  },
  {
    path: 'product',
    component: ProductComponent,
  },
  {
    path: 'product/:name',
    component: ProductDetailComponent,
  },
  {
    path: 'store',
    component: StoreComponent,
  },
  {
    path: 'store/:name',
    component: StoreDetailComponent,
  },
  {
    path: 'checkout',
    component: CheckoutComponent,
  },
  {
    path: 'checkout/success/:orderId',
    component: PaymentSuccessComponent
  },
  {
    path: 'about',
    component: AboutUsComponent,
  },  {
    path: 'terms-condition',
    component: TermsComponent,
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./user-dashboard/user-dashboard.module').then(m => m.UserDashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: '404', 
    component: NotFoundComponent,
  },
  // {
  //   path: '**', 
  //   redirectTo: '/404',
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
