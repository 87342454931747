import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateText'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit = 25, innerHtml = true, ellipsis = '...') {
    if (innerHtml) {
      value = value.replace(/<(?:.|\n)*?>/gm, '');
    }
    return value.length > limit ? value.substr(0, limit) + ellipsis : value;
  }
}