import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { OrderToSubmit } from '../interface/order.interface';
import { CartService } from '../service/cart.service';
import { CommonService } from '../service/common.service';
import { UserService } from '../service/user.service';
declare var Square: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  isSqFormLoading: boolean = true;
  isLoading: boolean = false;
  paymentForm: any; //this is our payment form object

  order: OrderToSubmit;

  constructor(
    private commonService: CommonService,
    private userService: UserService,
    private router: Router,
    private cartService: CartService,
    public dialogRef: MatDialogRef<PaymentComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
  ) { 
    this.commonService.updateTitle('Payment');
  }

  ngOnInit() {
    if(!this.data.order) {
      this.commonService.alertDialog('Error!', 'Order not found');
      return;
    }
    this.order = this.data.order;
    this.initializeCard();
  }

  private async initializeCard(): Promise<void> {
    const payments = Square.payments(environment.squarePayment.applicationId, environment.squarePayment.locationId);

    const cardStyle = {
      '.input-container': {
        borderColor: '#E0E2E3',
        borderRadius: '4px',
      },
      '.input-container.is-focus': {
        borderColor: '#E0E2E3'
      },
      '.input-container.is-error': {
        borderColor: 'red',
      }
    };
    
    try {
      this.paymentForm = await payments.card({style: cardStyle});
      await this.paymentForm.attach("#card-container");
      this.toggleSqFormLoading();
    } catch (e) {
      console.error('Initializing Card failed', e);
      return;
    }
  }

  async handlePaymentMethodSubmission(event) {
    event.preventDefault();
    try {
      this.setIsLoading(true);
      const token = await this.tokenize(this.paymentForm);
      this.submitOrder(token);
    } catch (e) {
      this.setIsLoading(false);
      console.error(e.message);
    }
  }

  // This function tokenizes a payment method. 
  // The ‘error’ thrown from this async function denotes a failed tokenization,
  // which is due to buyer error (such as an expired card). It is up to the
  // developer to handle the error and provide the buyer the chance to fix
  // their mistakes.
  async tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }
      throw new Error(errorMessage);
    }
  }

  private toggleSqFormLoading(): void {
    this.isSqFormLoading = !this.isSqFormLoading;
  }

  private setIsLoading(state: boolean): void {
    this.isLoading = state;
  }

  private submitOrder(nonce: string): void {
    this.order.nonce = nonce;
    this.userService.submitOrder(this.order).subscribe((response) => {
      this.setIsLoading(false);
      if(!response.success) {
        this.commonService.alertDialog('Error!', response.message);
        return;
      }
      this.cartService.destroy();
      this.updateProfilePoints();
      const orderedData = response.data;

      // this.router.navigate(['dashboard/order/', orderedData.orderno]); //no checkout success page
      this.userService.storePaymentOrder(orderedData); //checkout success page
      this.router.navigate(['checkout/success/', orderedData.orderno]); //checkout success page
      
      this.dialogRef.close();
    });
  }

  private updateProfilePoints(): void {
    this.userService.getProfile().subscribe((response) => {
      if(!response.success && response.message.includes('Invalid session')) {
        this.userService.logout();
        return;
      }
      const user = response.data[0];
      this.userService.updateUser({points: user.points, pointvalue: user.pointvalue });
    });
  }

  testorder(): void {
    let test = {
      data: {
        delivery_value: null,
        collect_code: 'test',
        grand_total: "100.92",
        order_list: [{
          "prod_id":"2",
          "price":"12.99",
          "name":"Chicken Katsu Hand Roll",
          "quantity":5,
          "option":[{
            "option_id":"1",
            "option_price":"0.00",
            "option_name":"Japanese White Rice"
          },{
            "option_id":"3",
            "option_price":"0.00",
            "option_name":"Regular Mayonaise"
          }]
        },
        {
          "prod_id":"1",
          "price":"9.99",
          "name":"California Hand Roll",
          "quantity":3,
          "option":[{
            "option_id":"10",
            "option_price":"2.00",
            "option_name":"Basmati Rice"
          },{
            "option_id":"3",
            "option_price":"0.00",
            "option_name":"Regular Mayonaise"
          }]
        }],
        orderdate: "2021-01-08 13:37:27",
        orderno: "000206",
        payment_type: "online",
        total_value: "100.92",
        transaction_id: null,
        user_id: "46",
      },
      message: "Order Create Success",
      success: 1,
    }

    this.cartService.destroy();
    this.updateProfilePoints();
    const orderedData = test.data;

    // this.router.navigate(['dashboard/order/', orderedData.orderno]); //no checkout success page
    this.userService.storePaymentOrder(orderedData); //checkout success page
    this.router.navigate(['checkout/success/', orderedData.orderno]); //checkout success page

    this.dialogRef.close();
  }
}