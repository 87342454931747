<div class="page-content">
  <section class="home-menu">
    <div class="container-fluid">
      <div class="col-12 mb-4">
        <a class="text-underline primary-text" [routerLink]="['/']"> << Back</a>
      </div>

      <div class="section-title">
        <h3>Menu</h3>
      </div>
      <form [formGroup]="form">
        <div class="d-flex justify-content-between align-items-center flex-wrap">
          <div>
            <mat-form-field class="mr-3" appearance="outline">
              <mat-label>Category</mat-label>
              <mat-select formControlName="category">
                <mat-option value="ALL CATEGORY">All</mat-option>
                <mat-option [value]="category.id" *ngFor="let category of categoryList">{{category.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="store-field-padding" appearance="outline">
              <mat-label>Store selected</mat-label>
              <button mat-button class="w-100" (click)="selectStore()">
                <input matInput class="cursor-pointer" [value]="selectedStore && selectedStore.name ? selectedStore.name : 'Select Store'" readonly>
              </button>
            </mat-form-field>
          </div>
          <div class="sort-wrap">
            <mat-form-field appearance="outline">
              <mat-label>Sort By</mat-label>
              <mat-select formControlName="sort">
                <mat-option value="alphaup">A - Z</mat-option>
                <mat-option value="alphadown">Z - A</mat-option>
                <mat-option value="priceup">$ - $$$</mat-option>
                <mat-option value="pricedown">$$$ - $</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </form>
      
      <div class="product">
        <div class="row" [hidden]="isProductLoading || !productList">
          <div *ngFor="let product of productList" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6 product-wrap mb-30">
            <div class="box">
              <div class="product-img"><a role="button" (click)="selectProduct(product)"><img [src]="product.prod_image" alt="menu"></a></div>
              <div class="product-title"><a role="button" (click)="selectProduct(product)">{{product.prod_name}}</a></div>
              <div class="product-excerpt text-capitalize" [innerHTML]="product.prod_pack_type | sanitizeHtml"></div>
              <div class="text-bottom-wrap product-price">{{product.prod_price | currency}}</div>
              <div class="btn-bottom-wrap">
                <button class="btn btn-add-to-cart mt-2" (click)="selectProduct(product)">Add To Cart</button>
              </div>
            </div>
          </div>
        </div>

        <!-- ghost element -->
        <ng-template [ngIf]="isProductLoading">
          <div class="row ghost-ui">
            <div *ngFor="let item of [0, 1, 2, 3, 4, 5]" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6 product-wrap mb-30">
              <div class="box">
                <div class="product-img shimmer"></div>
                <div class="product-title shimmer"></div>
                <div class="product-excerpt shimmer"></div>
                <div class="product-price shimmer"></div>
              </div>
            </div>
          </div>
        </ng-template>

        <!-- no products -->
        <ng-template [ngIf]="!isProductLoading && !productList">
          <div>
            <p>No products found.</p>
          </div>
        </ng-template>
      </div>
    </div>
  </section>
</div>