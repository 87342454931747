
<button class="close" mat-icon-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>
<h1 mat-dialog-title *ngIf="title">{{title}}</h1>
<div mat-dialog-content class="text-center">
  <div class="mb-3">
    <qrcode [qrdata]="code" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
  </div>
  <ng-template [ngIf]="message">
    <span>{{message}}</span>
  </ng-template>
  <ng-template [ngIf]="voucher && !isLoading">
    <button class="btn btn-redeem mt-2" 
      (click)="selectVoucher(voucher)"
      [disabled]="isMatched">
      <span *ngIf="!isMatched">Apply to Cart</span>
      <span *ngIf="isMatched">Applied</span>
    </button>
  </ng-template>
</div>
