
<button class="close" mat-icon-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title *ngIf="title" class="font-weight-medium">{{title}}</h1>
<div mat-dialog-content class="text-center">
  <div class="mb-3">
    <qrcode [qrdata]="code" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
  </div>
  <ng-template [ngIf]="message">
    <span class="white-space">{{message}}</span>
  </ng-template>
</div>
