<div class="page-content">
  <section class="store">
    <div class="container">
      <div class="col-12 mb-4">
        <a class="text-underline primary-text" [routerLink]="['/store']"> << Back to Store Selection</a>
      </div>
      <div class="section-title">
        <h3 class="text-left">Store Detail</h3>
      </div>
      <div *ngIf="store" class="row">
        <div class="col-lg-12 col-md-12">
          <div class="border-bottom pb-2 mb-3">
            <span class="f24 font-weight-bold mr-3">{{store.name}}</span>
            <span class="f14">{{store.distance}}</span>
            <div>{{store.address}}</div>
          </div>
          
          <div class="mb-4">
            <img [src]="store.image" [alt]="store.name" [title]="store.name" style="width: 100%;">
          </div>
          
          <div class="f24 border-bottom pb-2 mb-3">Visiting Information</div>
          <div class="store-phone">
            <div class="icon-text"><i class="fas fa-mobile-alt"></i></div>
            <div>{{store.phone}}</div>
          </div>
          <div class="store-email">
            <div class="icon-text"><i class="far fa-envelope"></i></div>
            <div>{{store.email ? store.email : '-'}}</div>
          </div>
          <div class="store-time">
            <div class="icon-text"><i class="fas fa-calendar-day"></i></div>
            <div>
              <span *ngFor="let open of store.openhrs">
                <div>{{open.day_of_week}} {{open.open_time}} - {{open.close_time}}</div>
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- ghost element -->
      <ng-template [ngIf]="!store">
        <div class="row ghost-ui">
          <div class="col-lg-12 col-md-12">
            <div class="shimmer pb-2 mb-3">
              <span class="shimmer f24 mr-3"></span>
              <span class="shimmer f14"></span>
            </div>
            <div class="shimmer mb-4"></div>
            <div class="shimmer f24 pb-2 mb-3"></div>
          </div>
        </div>
      </ng-template>
    </div>
  </section>
</div>
