import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-qr-popup',
  templateUrl: './qr-popup.component.html',
  styleUrls: ['./qr-popup.component.css']
})
export class QrPopupComponent implements OnInit {

  message: string;
  title: string;
  code: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    public dialogRef: MatDialogRef<QrPopupComponent>
  ) { 
    if(this.data) {
      this.title = this.data.title;
      this.message = this.data.message;
      this.code = this.data.code;
    }
  }

  ngOnInit(): void {

  }
}
