<button class="close" mat-icon-button (click)="onClose()">
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>Payment Options</h1>
<div mat-dialog-content>
  <div *ngIf="paymentOptions">
    <div *ngIf="user" class="row mb-5">
      <div class="profile-img col-3">
        <!-- <div class="thumb">
          <img [src]="user.avatar" alt="profile-image">
        </div> -->
        <img [src]="user.avatar" class="w-100 h-auto payment-profile-img">
      </div>
      <div class="col">
        <div>Available Points</div>
        <div>
          <span class="font-weight-medium f18 mr-3">{{user.points}} Points</span> 
          <span class="f14">{{user.pointvalue | currency}}</span>
        </div>
        <div *ngIf="store" class="payment-store">
          <span class="font-weight-bold">Selected Store: {{store.name}}</span>
        </div>
      </div>
    </div>

    <div *ngIf="paymentOptions.partialPaymentButton" class="mb-3">
      <button class="btn btn-payoption partial-pay"(click)="selectOption(paymentOptions.partialPaymentButton[0].partialPayAmount, 'Pay with Points + Card', 'redeem')">
        <div class="font-weight-medium">Pay with Points + Card</div>
        <div class="f14">{{paymentOptions.partialPaymentButton[0].pointsToDeduct}} Points + {{paymentOptions.partialPaymentButton[0].partialPayAmount | currency}}</div>
      </button>
    </div>
    <div *ngIf="paymentOptions.fullPaymentButton">
      <button class="btn btn-payoption full-pay" (click)="selectOption(paymentOptions.fullPaymentButton, 'Pay with Card', 'online')">
        <div class="font-weight-medium">Pay with Card</div>
        <div class="f14">{{paymentOptions.fullPaymentButton | currency }}</div>
      </button>
    </div>
    <!-- <div *ngIf="isSkipPayment">
      <button class="btn btn-payoption full-pay" (click)="selectOption(0, 'Pay with Card', 'online')">
        <div class="font-weight-medium">Pay with Card</div>
        <div class="f14">{{ 0 | currency }}</div>
      </button>
    </div> -->
  </div>

  <ng-template [ngIf]="errorMessage">
    <span>{{errorMessage}}</span>
  </ng-template>

  <ng-template [ngIf]="!errorMessage && !paymentOptions">
    <span>Loading ...</span>
  </ng-template>
</div>