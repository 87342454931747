import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuCategory, MenuProduct, MenuPromotion, Store } from '../interface/menu.interface';
import { CommonService } from '../service/common.service';
import { MenuService } from '../service/menu.service';
import { UserService } from '../service/user.service';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {

  promotionOption: any = {
    loop: true,
    autoplay: true,
    dots: false,
    items: 1,
    dotsEach: true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn'
  }
  categoryOption: any = {
    loop: false,
    responsiveClass: true,
    nav: true,
    dots: false,
    items: 6,
    navText : ['<i class="fas fa-chevron-left"></i>','<i class="fas fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 4,
      },
      644: {
        items: 4,
      },
      1060: {
        items: 6,
      },
    }
  }

  promotionList: MenuPromotion[];
  categoryList: MenuCategory[];
  bestSellerList: MenuProduct[];
  newLineList: MenuProduct[];

  selectedStore: Store;
  promotionPlaceholder: boolean = false;
  private unsubscribe$ = new Subject();

  constructor(
    private menuService: MenuService,
    private userService: UserService,
    private commonService: CommonService,
    private router: Router,
  ) { 
    this.commonService.updateTitle('Order Online');
  }

  ngOnInit(): void {
    this.userService.selectedStore$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((data) => {
      if(!data) {
        return;
      }
      this.selectedStore = data;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // promotions
      this.menuService.promotions$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if(!data) {
          return;
        }
        if(data.length < 1) {
          this.promotionPlaceholder = true;
          return;
        }
        this.promotionPlaceholder = false;
        this.promotionList = data.filter(promo => promo.image_web);
      });

      // categories
      this.menuService.categories$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if(!data) {
          return;
        }
        this.categoryList = data;
      });

      // bestseller products
      this.menuService.bestsellers$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if(!data) {
          this.bestSellerList = [];
          return;
        }
        this.bestSellerList = data;
      });

      // newline products
      this.menuService.newlines$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if(!data) {
          this.newLineList = [];
          return;
        }
        this.newLineList = data;
      });
    }, 0)
  }

  slug(str: string): string {
    return this.commonService.dashCase(str);
  }

  selectProduct(product: MenuProduct): void {
    this.menuService.setSelectedProduct(product);
    this.router.navigate(['product', this.slug(product.prod_name)]);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
