<button class="close" mat-icon-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>
<h1 mat-dialog-title>Pickup details</h1>
<div mat-dialog-content *ngIf="isLoggedIn">
  <div>
    <h6>Select Date</h6>
    <mat-form-field class="full-width-field" appearance="outline">
      <input #datePickerModel="ngModel" matInput 
          [matDatepicker]="datepicker" 
          [min]="minDate" 
          [max]="maxDate"
          [(ngModel)]="this.selectedDatetime.pickupDate" 
          [matDatepickerFilter]="dateFilter" 
          (dateChange)="onSelectedDate()"
          placeholder="Select pickup date"
      readonly required>
      <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
      <mat-datepicker touchUi="false" #datepicker disabled="false"></mat-datepicker>
    </mat-form-field>
    <mat-error *ngIf="datePickerModel.hasError('matDatepickerMin')">
      Minimum date should be {{ maxDate | date }}
    </mat-error>
  </div>
  <div>
    <h6>Select time</h6>
    <div class="primary-text" *ngIf="(!timeIntervals && errorMessage)">{{errorMessage}}</div>
    <mat-form-field class="full-width-field" appearance="outline" [hidden]="(!timeIntervals && errorMessage)">
      <mat-icon matSuffix>schedule</mat-icon>
      <mat-select [(value)]="this.selectedDatetime.pickupTime" placeholder="Select pickup time" required>
        <mat-option *ngIf="(!timeIntervals || timeIntervals.length < 1) && !errorMessage">No time slot available</mat-option>
        <mat-option *ngFor="let time of timeIntervals" [value]="time">{{time}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<ng-container *ngIf="!isLoggedIn">
  <div class="primary-text">Please login to continue.</div>
</ng-container>
    
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="onClose()">{{buttonText}}</button>
</div>
