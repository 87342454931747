import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuCategory, MenuProduct, Store } from '../interface/menu.interface';
import { StorePopupComponent } from '../popup/store-popup/store-popup.component';
import { CommonService } from '../service/common.service';
import { MenuService } from '../service/menu.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit, AfterViewInit, OnDestroy {

  productList: MenuProduct[];
  categoryList: MenuCategory[];
  form: FormGroup;
  isProductLoading: boolean = true;

  selectedStore: Store;
  private unsubscribe$ = new Subject();

  constructor(
    private menuService: MenuService,
    private fb: FormBuilder,
    private userService: UserService,
    private commonService: CommonService,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.commonService.updateTitle('Products');
    const selectedCategory: string = this.router.getCurrentNavigation().extras.state ? this.router.getCurrentNavigation().extras.state.category : 'ALL CATEGORY';
    const deviceId = this.userService.getDeviceId();

    this.form = this.fb.group({
      sort: ['alphaup', [Validators.required]],
      category: [selectedCategory, [Validators.required]],
      deviceID: [deviceId],
      keyword: [''],
    });
  }

  ngOnInit(): void {
    this.userService.selectedStore$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((data) => {
      if(!data) {
        return;
      }
      this.selectedStore = data;

      // products
      let value = this.form.value;
      this.menuService.getProduct(value, this.selectedStore.id).subscribe((response) => {
        this.isProductLoading = false;
        this.productList = response.data;
      });
    });

    // categories
    this.menuService.categories$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((data) => {
      if(!data) {
        return;
      }
      this.categoryList = data.filter(category => category.allow_order === '1');
    });
  }

  ngAfterViewInit(): void {
    // detect changes on sort & category
    this.form.valueChanges.subscribe((changes) => {
      this.isProductLoading = true;
      this.menuService.getProduct(changes, this.selectedStore.id).subscribe((response) => {
        this.isProductLoading = false;
        this.productList = response.data;
        return;
      });
    });
  }

  selectProduct(product: MenuProduct): void {
    this.menuService.setSelectedProduct(product);
    this.router.navigate(['product', this.commonService.dashCase(product.prod_name)]);
  }

  selectStore(): void {
    const dialogRef = this.dialog.open(StorePopupComponent, {
      width: '800px',
      disableClose: true
    });
    // dialogRef.afterClosed().subscribe((data) => {
    //   if(data) {
    //     // console.log('product here')
    //     // this.userService.removeShowPromotion();
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
