import { AfterViewInit, Component, KeyValueDiffers, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Country } from '../interface/common.interface';
import { TermsPopupComponent } from '../popup/terms-popup/terms-popup.component';
import { AuthenticateService } from '../service/authenticate.service';
import { CommonService } from '../service/common.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, AfterViewInit, OnDestroy {

  errorMessage: string = null;
  activeTab: string = 'email';
  form: FormGroup;
  tacCode: FormGroup;
  showPassword: Boolean = false;
  tncCheck: Boolean = false;

  countryCtrl = new FormControl();
  filteredCountries: Observable<Country[]>;
  options: Country[];

  isLoading: boolean = false;

  content: any;

  registerValidate: Boolean = false;
  private unsubscribe$ = new Subject();

  siteKey: string = environment.recaptchaSiteKey;

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticateService,
    private userService: UserService,
    private commonService: CommonService,
    private router: Router,
    private dialog: MatDialog
  ) { 
    this.commonService.updateTitle('Register');
    const deviceId = this.userService.getDeviceId();
    this.form = this.fb.group({
      type: [this.activeTab, [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      emailAddress: [''],
      mobileNo: [''],
      countryID: [''],
      postcode: [''],
      password: [''],
      confirmPassword: [''],
      facebookId: [''],
      deviceID: [deviceId],
      notificationToken: [''],
      avatar: [''],
      refCode: [''],
      recaptcha: ['']
    });

    this.tacCode = this.fb.group({
      code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
    });
  }

  ngOnInit(): void {
    this.authService.initializeFacebookSdk();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.userService.content$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if(!data) {
          this.userService.getAppContent().subscribe((response) => {
            if(response.data[0]) {
              this.userService.storeContent(response.data[0]);
            }
          });
          return;
        }
        this.content = data;
      })

      this.authService.countries$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if(!data) {
          this.authService.getCountries().subscribe((response) => {
            this.options = response.data;
            this.authService.setCountries(response.data);
          });
          return;
        }
        this.options = data;
      });

      // autocomplete countries input
      this.filteredCountries = this.countryCtrl.valueChanges
      .pipe(
        startWith(''),
        map(country => {
          this.form.patchValue({
            countryID: null
          });
          if(country) {
            return this.filterCountryQuery(country)
          }
          if(this.options) {
            return this.options.slice();
          }
        })
      );
    }, 0);

    this.tacCode.valueChanges
    .subscribe(() => {
      if (this.tacCode.valid) {
        this.onSubmitTac();
      }
    });
  }

  resolved(captchaResponse: string) {
    this.form.patchValue({
      recaptcha: captchaResponse
    });
  }

  private filterCountryQuery(value: string): Country[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(country => country.phonecode.toLowerCase().indexOf(filterValue) === 0);
  }

  selectCountry(country: Country) {
    this.form.patchValue({
      countryID: country.id
    });
  }

  submitRegister() {
    this.errorMessage = null;
    if(this.form.invalid) {
      this.errorMessage = 'Incomplete data, please make sure all the mandatory field are filled.';
      return;
    }

    let value = this.form.value;

    // check password match
    if(value.password !== value.confirmPassword) {
      this.errorMessage = 'Password does not match your confirm password.';
      return;
    }
    // check recaptcha
    if(!value.recaptcha) {
      this.errorMessage = 'Please check the reCAPTCHA';
      return;
    }

    let countryCtrlValue = this.countryCtrl.value ? this.countryCtrl.value.trim() : this.countryCtrl.value;

    // check empty countryID
    if(countryCtrlValue == '' || !countryCtrlValue) {
      this.errorMessage = 'Please select a valid phone code.';
      return;
    }

    // check valid countryID
    if(countryCtrlValue) {
      const selectedCountry = this.options.find(country => country.phonecode == countryCtrlValue);
      if(selectedCountry) {
        this.selectCountry(selectedCountry); //find and append countryID
      } else {
        this.errorMessage = 'Please select a valid phone code.';
        return;
      }

      if(!value.mobileNo) {
        this.errorMessage = 'Incomplete data, please make sure all the mandatory field are filled.';
        return;
      }
    }

    value = this.form.value;

    // check empty countryID again
    if(!value.countryID) {
      this.errorMessage = 'Please select a valid phone code.';
      return;
    }

    this.errorMessage = null;

    delete value['facebookId'];

    if(value.type == 'mobile') {
      delete value['emailAddress'];
    }

    // alert(`Done, not sending to register API, countryID: ${value.countryID}, mobileNo: ${value.mobileNo}`)

    // post request
    this.isLoading = true;
    this.authService.register(value).subscribe((response) => {
      this.isLoading = false;
      this.registerValidate = response.success;
      if(!response.success) {
        this.errorMessage = response.message;
        return;
      }
      this.commonService.alertDialog('Done!', response.message);
    });
  }

  async submitFacebookRegister() {
    const loginResponse = await this.authService.getFacebookLogin();
    if(loginResponse && loginResponse.authResponse) {
      const accessToken = loginResponse.authResponse.accessToken;
      const fbUser = await this.authService.getFacebookUser(accessToken);
      if(fbUser) {
        this.form.patchValue({
          type: 'facebook',
          facebookId: fbUser.id,
          firstName: fbUser.first_name,
          lastName: fbUser.last_name,
          emailAddress: fbUser.email,
        });
        if(fbUser.picture && fbUser.picture.data.url) {
          let base64img = await this.getBase64ImageFromUrl(fbUser.picture.data.url);
          base64img = base64img.split("base64,")[1];
          this.form.patchValue({
            avatar: base64img
          });
        }
        //register
        let value = this.form.value;
        this.errorMessage = null;

        this.authService.register(value).subscribe((response) => {
          this.isLoading = false;
          this.registerValidate = response.success;
          if(!response.success) {
            this.errorMessage = response.message;
            return;
          }
          this.commonService.alertDialog('Done!', response.message);
          //go to homepage
          if(response.data && response.data.length >0) {
            this.navigateWithCampaign(response.data[0]);
          } else {
            this.router.navigate(['login']);
          }
        });
        //ended
      }
    } else {
      this.commonService.alertDialog('Error!', 'ERR. Please try again.');
      this.isLoading = false;
    }
  }


  async getBase64ImageFromUrl(imageUrl: string): Promise<any> {
    var res = await fetch(imageUrl);
    var blob = await res.blob();
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  onResendTac(): void {
    const value = this.form.value;
    let postData = { 
      type: value.type,
      emailAddress: value.emailAddress,
      mobileNo: value.mobileNo,
      deviceID: value.deviceID,
    }
    this.isLoading = true;
    this.authService.resendTacCode(postData).subscribe((response) => {
      this.isLoading = false;
      if(!response.success) {
        this.commonService.alertDialog('Error!', response.message);
        return;
      }
      this.commonService.alertDialog('Done!', response.message);
    });
  }

  onSubmitTac(): void {
    const value = this.form.value;
    let postData = { 
      type: value.type,
      emailAddress: value.emailAddress,
      mobileNo: value.mobileNo,
      deviceID: value.deviceID,
      activationCode: this.tacCode.value.code
    }
    this.isLoading = true;
    this.authService.activateAccount(postData).subscribe((response) => {
      this.isLoading = false;
      if(!response.success) {
        this.commonService.alertDialog('Error!', response.message);
        this.tacCode.reset();
        return;
      }
      this.commonService.alertDialog('Done!', response.message);
      // this.router.navigate(['login']);
      this.navigateWithCampaign(response.data[0]); //go to homepage
    });
  }

  onClickShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  setActiveTab(tab: string): void {
    this.activeTab = tab;
    this.errorMessage = null;
    this.tncCheck = false;
    if(this.form.value.type !== 'facebook') {
      this.form.patchValue({ type: tab });
    }
  }

  onTermPopup(): void {
    this.dialog.open(TermsPopupComponent, {
      width: '1000px',
      height: '700px'
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private navigateWithCampaign(userData): void {
    this.userService.clearPopupConfigs();
    this.userService.updateUser(userData);
    this.userService.updateUserStatus(true);
    this.router.navigate(['/']);
  }
}