<button class="close" mat-icon-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title class="text-center">Reset Password Change</h1>
<div mat-dialog-content class="text-center">
  We noticed that you had recently requested to reset your password. <br/>
  If you'd like to change the password to something easier to remember, you can do so in the Profile section.
</div>
<div mat-dialog-actions class="d-flex justify-content-center">
  <button mat-raised-button color="primary" class="w-100" (click)="goToChangePassword()">Take me there!</button>
</div>