import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ApiResponse } from '../interface/common.interface';
import { MenuPromotion, Store } from '../interface/menu.interface';
import { CommonService } from '../service/common.service';
import { MenuService } from '../service/menu.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.css']
})
export class PromotionComponent implements OnInit, AfterViewInit, OnDestroy {

  promotionList: MenuPromotion[];
  selectedStore: Store;
  private unsubscribe$ = new Subject();

  constructor(
    private menuService: MenuService,
    private commonService: CommonService,
    private userService: UserService
  ) { 
    this.commonService.updateTitle('Promotions');
  }

  ngOnInit(): void {

  }
  
  ngAfterViewInit(): void {
    setTimeout(() => {
      // this.userService.selectedStore$
      // .pipe(takeUntil(this.unsubscribe$))
      // .subscribe((data) => {
      //   this.selectedStore = data;
      //   this.menuService.getPromotion(this.selectedStore?.id).subscribe((response) => {
      //     if(!response.success) {
      //       if(response.message.toLowerCase().includes('no promotion')) {
      //         this.promotionList = [];
      //       }
      //       return;
      //     }
      //     this.menuService.setPromotion(response.data);
      //   });
      // });
      this.menuService.promotions$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if(!data) {
          return;
        }
        this.promotionList = data.filter(promo => promo.image_web);
      });
    });
  }

  slug(str: string): string {
    return this.commonService.dashCase(str);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
