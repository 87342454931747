import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-popup',
  templateUrl: './dialog-popup.component.html',
  styleUrls: ['./dialog-popup.component.css']
})
export class DialogPopupComponent implements OnInit {

  message: string;
  title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
  ) { 
    if(this.data) {
      this.title = this.data.title;
      this.message = this.data.message;
    }
  }
  
  ngOnInit(): void {
  }
}
