<div class="page-content">
  <section class="about">
    <div class="container">
      <div class="section-title">
        <h3 class="text-left">About Us</h3>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="text-center mb-40">
            <img class="w-100" src="assets/images/about.png">
          </div>
          <div class="about-content">
            <p>In 2011 Sushi Izu formed a partnership with Woolworths to provide convenient, restaurant quality sushi at affordable prices, using the highest quality ingredients made fresh in-store every day.</p>
            <p>Today, we’re Australia’s largest sushi retailer with over 200 stores nationwide.</p>
            <p>Our parent company Advanced Fresh Concepts has over 4,000 stores throughout the USA and Canada.</p>
            <p>We combine traditional Japanese methods with modern styles to create our tasty range of hybrid Japanese meals.</p>
            <p>All of our food is prepared under strict systems and processes that ensure our customers receive only the freshest and tastiest food every day.</p>
            <p>You’ll find our stores in over 200 Woolworths nationwide.</p>
            <p>All of our chefs have been trained according to our strict guidelines that have been developed by our parent company over the past 40 years.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>