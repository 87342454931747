<div class="page-content">
  <section class="promotion">
    <div class="container-fluid">
      <div class="col-12 mb-4">
        <a class="text-underline primary-text" [routerLink]="['/']"> << Back</a>
      </div>
      <div class="section-title">
        <h3>Promotions</h3>
      </div>
      <div class="row">
        <div *ngFor="let promo of promotionList" class="col-md-4 col-sm-6">
          <div class="box">
            <a [routerLink]="['/promotion', slug(promo.name)]">
              <img [src]="promo.image_web" [alt]="promo.name" [title]="promo.name" style="width: 100%;">
              <div class="promotion-title">{{promo.name}}</div>
            </a>
          </div>
        </div>
      </div>

      <ng-template [ngIf]="promotionList && promotionList.length === 0">
        <div class="row">
          <div class="col text-center">
            There are currently no active promotions. Stay tuned for our exciting offers.
          </div>
        </div>
      </ng-template>

      <!-- ghost element -->
      <ng-template [ngIf]="!promotionList">
        <div class="row ghost-ui">
          <div *ngFor="let item of [0, 1, 2]" class="col-md-4 col-sm-6">
            <div class="box shimmer">
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </section>
</div>