<!-- <div id="main">
  <mat-drawer-container class="example-container" autosize [hasBackdrop]="true">
    <mat-drawer #drawer class="example-sidenav" mode="side">
      <ul class="content list-unstyled sidenav-box">
        <li><a [routerLink]="['.']">Home</a></li>
        <li><a [routerLink]="['/product']">Menu</a></li>
        <li><a [routerLink]="['/store']">Stores</a></li>
        <li><a [routerLink]="['/about']">About Sushi Izu</a></li>
        <li><a href="https://www.sushiizu.com.au/terms-and-privacy/">Terms & Privacy</a></li>
        <li *ngIf="user && user.loggedin"><a role="button" (click)="logout()">Logout</a></li>
      </ul>
    </mat-drawer>
    
    <div class="site-content mh-96">
      <nav class="navbar navbar-expand-lg navbar-dark">
        <div class="container-fluid">
          <button type="button" mat-button (click)="drawer.toggle()">
            <i class="text-white f40 fa fa-bars"></i>
          </button>
          <a class="navbar-brand" [routerLink]="['.']">
            <img src="assets/images/sushi-izu-logo.png" alt="Logo">
          </a>
          <div class="text-right top-login">
            <a *ngIf="!user || (user && !user.loggedin)" [routerLink]="['/login']" class="mr-2">Login</a>
            <a *ngIf="user && user.loggedin" [routerLink]="['/dashboard/purchase']" class="mr-2">Hi, {{user.firstname}}</a>
            <a [routerLink]="['/checkout']" mat-icon-button><i class="fa fa-shopping-cart" alt="cart"></i></a>
          </div>
        </div>
      </nav>
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-container>
</div>
<app-footer></app-footer> -->

<div id="main">
  <div class="site-content mh-96">
    <app-header></app-header>
    <router-outlet></router-outlet>
  </div>
</div>
<!-- <app-footer></app-footer> -->