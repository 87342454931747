import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Store } from 'src/app/interface/menu.interface';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-signin-popup',
  templateUrl: './signin-popup.component.html',
  styleUrls: ['./signin-popup.component.css']
})
export class SigninPopupComponent implements OnInit {

  constructor(
    private userService: UserService
  ) { }

  ngOnInit(): void {
  }

}
