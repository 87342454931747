<button class="close" mat-icon-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<div mat-dialog-content *ngIf="!viewTnC">
  <!-- <owl-carousel [options]="promotionOption" [carouselClasses]="['owl-theme', 'owl-banner']" #owlElement>
    <div class="item" *ngFor="let promo of promotions">
      <img class="promotion-popup-img" [src]="promo.image_web" [alt]="promo.name" [title]="promo.name">
      <div class="mt-3">
        <div class="desc promo-desc font-weight-bold mb-2" [innerHTML]="promo.name | sanitizeHtml"></div>
        <div class="desc promo-desc" [innerHTML]="promo.desc | sanitizeHtml"></div>
      </div>
    </div>
  </owl-carousel> -->
  <div class="item mt-3">
    <img class="campaign-popup-img w-100" [src]="campaign.image_web" [alt]="campaign.name" [title]="campaign.name">
    <div class="mt-3">
      <!-- <div class="desc promo-desc font-weight-bold mb-2" [innerHTML]="campaign.name | sanitizeHtml"></div> -->
      <div class="desc promo-desc text-justify" [innerHTML]="campaign.desc | sanitizeHtml"></div>
    </div>
    <div class="mt-3 text-center f14">Click Accept to join or Decline to skip</div>
  </div>
</div>

<div mat-dialog-content *ngIf="viewTnC">
  <div (click)="viewTnC = false" class="d-flex align-items-center cursor-pointer mb-4"><mat-icon>arrow_back_ios</mat-icon> Terms & Conditions</div>
  <object [data]="campaign.term | safeUrl" type="application/pdf" width="100%" height="500px"></object>
</div>

<div *ngIf="!viewTnC" mat-dialog-actions align="center" class="mb-1">
  <button mat-raised-button mat-dialog-close>Decline</button>
  <button mat-raised-button color="primary" mat-dialog-close="true">Accept</button>
</div>

<div *ngIf="!viewTnC" class="d-block text-center text-underline cursor-pointer f14" (click)="viewTnC = true">Click to view T&C's</div>


