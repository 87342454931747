import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.css']
})
export class AlertPopupComponent implements OnInit {

  message: string;
  title: string;
  action: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
  ) { 
    if(this.data) {
      this.title = this.data.title;
      this.message = this.data.message;
      this.action = this.data.action;
    }
  }

  ngOnInit(): void {
  }

}
