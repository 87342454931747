import { AfterViewInit, Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { CommonService } from '../service/common.service';
import { MenuService } from '../service/menu.service';
import { UserService } from '../service/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from '../interface/menu.interface';
import { CartService } from '../service/cart.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})
export class StoreComponent implements OnInit, AfterViewInit, OnDestroy {

  storeList: Store[];
  selectedStore: Store;

  searchText: string;
  checklocationpermission: boolean = false;

  private unsubscribe$ = new Subject();

  constructor(
    private menuService: MenuService,
    private userService: UserService,
    private cartService: CartService,
    private commonService: CommonService,
    private router: Router
  ) { 
    this.commonService.updateTitle('Stores');
  }

  ngOnInit(): void {
    let thisfunc = this;
    navigator.permissions && navigator.permissions.query({name: 'geolocation'})
    .then(function(PermissionStatus) {
      if (PermissionStatus.state == 'granted') {
        thisfunc.checklocationpermission = true;
      } else {
        thisfunc.checklocationpermission = false;
      }
    });
  }


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.userService.selectedStore$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((storeData) => {
        if(!storeData) {
          if(this.storeList) {
            this.userService.setSelectedStore(this.storeList[0]);
          }
          return;
        }
        this.selectedStore = storeData;
      });

      // stores
      this.userService.getUserLocationPayload().then((postData) => {
        // this.menuService.stores$
        // .pipe(takeUntil(this.unsubscribe$))
        // .subscribe((data) => {
        //   if(!data) {
            this.menuService.getStore(postData).subscribe((response) => {
              if(!response.success) {
                return;
              }
              this.menuService.setStore(response.data);
              this.storeList = response.data; //added
            });
        //     return;
        //   }
        //   this.storeList = data;
        // });
      });
    });
  }

  selectStore(store: Store): void {
    const cartItems = this.cartService.cartLength();
    if(cartItems > 0) {
      let dialogResponse = this.commonService.actionDialog('Alert', 'Your shopping cart will be emptied if you change your store selection', 'Continue');
      dialogResponse.subscribe(data => {
        if(data) {
          this.cartService.destroy();
          this.userService.setSelectedStore(store);
          this.router.navigate(['.']);
        }
      });
      return;
    }
    this.userService.setSelectedStore(store);
    this.router.navigate(['.']);
  }

  slug(str: string): string {
    return this.commonService.dashCase(str);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
