import { Component, Inject, OnInit } from '@angular/core';
import { UserService } from '../../service/user.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PromotionVoucher } from '../../interface/menu.interface';

@Component({
  selector: 'app-voucher-popup',
  templateUrl: './voucher-popup.component.html',
  styleUrls: ['./voucher-popup.component.css']
})
export class VoucherPopupComponent implements OnInit {
  
  vouchers: PromotionVoucher[];
  errorMessage: string;

  constructor(
    private userService: UserService,
    public dialogRef: MatDialogRef<VoucherPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private payload,
  ) { }

  ngOnInit(): void {
    this.userService.getWalletVoucher('true', this.payload.order_array).subscribe((response) => {
      if(!response || !response.success) {
        if(!response || response.message.includes('Invalid session')) {
          this.userService.logout();
          this.errorMessage = 'Please login to continue.';
          return;
        }
        this.errorMessage = response.message;
        return;
      }
      this.vouchers = response.data;
      if(!response.data) {
        this.errorMessage = "No vouchers found.";
        this.vouchers = [];
      }
    });
  }

  selectVoucher(voucher: PromotionVoucher): void {
    this.dialogRef.close(voucher);
  }
}
