import { Component, Inject, OnInit } from '@angular/core';
import { MenuService } from '../../service/menu.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderPickupDatetime } from '../../interface/order.interface';
import { StoreAvailability } from '../../interface/menu.interface';
import { UserService } from 'src/app/service/user.service';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-datetime-popup',
  templateUrl: './datetime-popup.component.html',
  styleUrls: ['./datetime-popup.component.css']
})
export class DatetimePopupComponent implements OnInit {

  errorMessage: string = null;
  minDate: Date = new Date();
  maxDays: number = 1000 * 60 * 60 * 24 * 14;
  maxDate = new Date(new Date().getTime() + this.maxDays);
  selectedDatetime: OrderPickupDatetime = { pickupDate: new Date(), pickupTime: '' };

  availabilityDates: StoreAvailability[];
  timeIntervals: string[];

  payload: any;
  selected: Boolean = false;
  isLoggedIn: boolean = true;
  buttonText: string = 'Done';

  constructor(
    private menuService: MenuService,
    private userService: UserService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private data,
    public dialogRef: MatDialogRef<DatetimePopupComponent>
  ) { }

  ngOnInit(): void {
    this.payload = this.data.payload;
    if(!this.payload.session) {
      this.buttonText = 'Login / Sign up now'
      this.isLoggedIn = false;
      return;
    }
    this.isLoggedIn = true;
    if(this.data.selectedDatetime) {
      this.selected = true;
      this.selectedDatetime = this.data.selectedDatetime;
    }
    this.getDateAvailable();
  }

  onSelectedDate(): void {
    this.getTimeAvailable(true);
  }

  private getDateAvailable(): void {
    this.menuService.storeOrderDateTime(this.payload, 'date').subscribe((response) => {
      if(!response.success) {
        if(response.message.includes('Invalid session')) {
          this.errorMessage = 'Please login to continue.';
          this.userService.logout();
        }
        return;
      }
      this.availabilityDates = response.data;
      this.maxDate = this.availabilityDates[this.availabilityDates.length - 1].date;
      if(!this.selected) {
        this.selectedDatetime.pickupDate = this.availabilityDates.filter((date) => date.is_avail === '1')[0].date; // get default available date
      }
      this.getTimeAvailable();
    });
  }

  private getTimeAvailable(newDate: boolean = false): void {
    const checkDate = new Date(this.selectedDatetime.pickupDate);
    this.payload.date =  this.commonService.formatDate(checkDate);

    this.menuService.storeOrderDateTime(this.payload, 'time').subscribe((response) => {
      if(!response.success) {
        if(response.message.includes('Invalid session')) {
          this.errorMessage = 'Please login to continue.';
          this.userService.logout();
        }
        if(response.message.includes('No time slot')) {
          this.errorMessage = response.message;
          this.selectedDatetime.pickupTime = null;
        }
        return;
      }
      this.timeIntervals = response.data;
      if(this.timeIntervals && !this.selected || newDate) {
        this.selectedDatetime.pickupTime = this.timeIntervals[0]; // get default time
      }
    });
  }

  dateFilter = (date: Date | null): boolean => {
    if(this.availabilityDates) {
      let available =  this.availabilityDates.find((item) => (new Date(item.date).toLocaleDateString() === date.toLocaleDateString() && item.is_avail === '1'));
      return(!!available);
    }
    return true;
  }

  onClose(): void {
    this.dialogRef.close(this.selectedDatetime);
  }
}
