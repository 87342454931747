import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuPromotion } from 'src/app/interface/menu.interface';
import { User } from 'src/app/interface/user.interface';
import { CommonService } from 'src/app/service/common.service';
import { MenuService } from 'src/app/service/menu.service';
import { UserService } from 'src/app/service/user.service';
declare var $: any;

@Component({
  selector: 'app-promotion-popup',
  templateUrl: './promotion-popup.component.html',
  styleUrls: ['./promotion-popup.component.css']
})
export class PromotionPopupComponent implements OnInit, OnDestroy {

  promotions: MenuPromotion;
  storeId: number;
  private unsubscribe$ = new Subject();
  promotionOption: any = {
    loop: false,
    rewind: true,
    autoplay: true,
    dots: true,
    items: 1,
    dotsEach: true,
  }
  constructor(
    private userService: UserService,
    private menuService: MenuService,
    private commonService: CommonService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) private data,
    public dialogRef: MatDialogRef<PromotionPopupComponent>
  ) { 
    this.promotions = this.data.promotionList;
    this.storeId = this.data.storeId;
  }

  ngOnInit(): void {
  }

  slug(str: string): string {
    return this.commonService.dashCase(str);
  }

  onCollectVoucher(voucherId: number): void {
    this.userService.user$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((userData: User) => {
      if(!userData || (userData && !userData.loggedin)) {
        this.commonService.alertDialog('Error!', 'Please login to claim the voucher', true);
        return;
      }
      if(userData.loggedin) {
        let postData = {
          item: voucherId,
          session: userData.session,
          deviceID: userData.deviceID
        }
        this.menuService.collectVoucher(postData).subscribe((response) => {
          if(!response.success) {
            if(response.message.toLowerCase().includes('invalid session') || response.message.toLowerCase().includes('session expired')) {
              this.commonService.alertDialog('Error!', response.message, true);
              return;
            }
            this.commonService.alertDialog('Error!', response.message, false);
            return;
          }
          this.commonService.alertDialog('Collected!', response.message);
        });
      } else {
        this.commonService.alertDialog('Error!', 'Please login to claim the voucher', true);
        return;
      }
    });
  }

  onViewAllPromotion(): void {
    this.router.navigate(['/promotion']);
    this.dialogRef.close(true);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
