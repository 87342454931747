<section class="page-content">
  <div class="container-fluid">
    
    <div class="pg-login">
      <div class="page-title">
        <h2>Login</h2>
      </div>
      <div class="register-tab">
        <ul class="nav nav-tabs">
          <li [class.active]="activeTab === 'email'">
            <a class="show" [class.active]="activeTab === 'email'" data-toggle="tab" (click)="setActiveTab('email')">Email</a>
          </li>
          <li [class.active]="activeTab === 'mobile'">
            <a data-toggle="tab" [class.active]="activeTab === 'mobile'" (click)="setActiveTab('mobile')">Mobile</a>
          </li>
        </ul>
        
        <div class="tab-content">
          <div *ngIf="activeTab === 'email'" [ngClass]="activeTab==='email'?'show active':'fade'" id="email" class="tab-pane">
            <div class="form-login-email">
              <form [formGroup]="form">
                <div class="row">
                  <div class="col-sm-12">
                    <ng-container *ngIf="errorMessage && activeTab === 'email'" class="col-sm-12">
                      <div class="alert alert-danger">{{ errorMessage }}</div>
                    </ng-container>
                    
                    <div class="form-group">
                      <mat-form-field class="w-100" appearance="legacy">
                        <mat-label>Email Address
                          <span *ngIf="form.controls.emailAddress.touched && form.controls.emailAddress.invalid">
                            <i *ngIf="form.controls.emailAddress.errors.required" class="fa fa-exclamation-circle text-alert"></i>
                          </span>
                        </mat-label>
                        <input matInput type="email" formControlName="emailAddress" id="email" name="email">
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group position-relative">
                      <mat-form-field class="w-100" appearance="legacy">
                        <mat-label>Password
                          <span *ngIf="form.controls.password.touched && form.controls.password.invalid">
                            <i *ngIf="form.controls.password.errors.required" class="fa fa-exclamation-circle text-alert"></i>
                          </span>
                        </mat-label>
                        <input matInput type="password" formControlName="password"name="password" [type]="showPassword ? 'text' : 'password'">
                        <i class="show-password-icon" [class]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="onClickShowPassword()"></i>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group submitform">
                      <button type="submit" class="btn btn-login" [disabled]="isLoading" (click)="submitLogin()">Login</button>
                    </div>
                  </div>
                  <div class="col-sm-12 text-center mb-4">
                    <button class="btn btn-facebook" [disabled]="isLoading" (click)="submitFacebookLogin()">Continue with Facebook</button>
                  </div>
                  <div class="col-sm-12">
                    <p class="text-center">Don't have account? <a [class.disabled]="isLoading" [routerLink]="['/register']">Sign Up</a></p>
                    <p class="text-center"><a [class.disabled]="isLoading" [routerLink]="['/forgot-password']">Forgot password?</a></p>
                  </div>
                </div><!-- end row-->
              </form>
            </div>
          </div><!-- end email form -->
          
          <div *ngIf="activeTab === 'mobile'" [ngClass]="activeTab==='mobile'?'show active':'fade'" id="mobile" class="tab-pane">
            <div class="form-login-email">
              <form [formGroup]="form">
                <div class="row">
                  <div class="col-sm-12">
                    <ng-container *ngIf="errorMessage && activeTab === 'mobile'" class="col-sm-12">
                      <div class="alert alert-danger">{{ errorMessage }}</div>
                    </ng-container>
                    <!-- <div class="form-group c-left">
                      <mat-form-field class="w-100" appearance="legacy">
                        <mat-label>Code</mat-label>
                        <input matInput
                          [matAutocomplete]="auto"
                          class="form-control" 
                          type="text"
                          id="code" 
                          name="code"
                          [formControl]="countryCtrl"
                          class="country-select">
                        <mat-autocomplete #auto="matAutocomplete" [panelWidth]="300">
                          <mat-option *ngFor="let country of filteredCountries | async" [value]="country.phonecode">
                            <span>{{country.phonecode}} ({{country.name}})</span>
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div> -->
                    <div class="form-group">
                      <mat-form-field class="w-100" appearance="legacy">
                        <mat-label>Mobile No
                          <span *ngIf="form.controls.mobileNo.touched && form.controls.mobileNo.invalid">
                            <i *ngIf="form.controls.mobileNo.errors.required" class="fa fa-exclamation-circle text-alert"></i>
                          </span>
                        </mat-label>
                        <input matInput type="text"  formControlName="mobileNo" id="mobileno" name="mobileno">
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group position-relative">
                      <mat-form-field class="w-100" appearance="legacy">
                        <mat-label>Password
                          <span *ngIf="form.controls.password.touched && form.controls.password.invalid">
                            <i *ngIf="form.controls.password.errors.required" class="fa fa-exclamation-circle text-alert"></i>
                          </span>
                        </mat-label>
                        <input matInput type="password" formControlName="password"name="password" [type]="showPassword ? 'text' : 'password'">
                        <i class="show-password-icon" [class]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="onClickShowPassword()"></i>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group submitform">
                      <button type="submit" class="btn btn-login" [disabled]="isLoading" (click)="submitLogin()">Login</button>
                    </div>
                  </div>
                  <div class="col-sm-12 text-center mb-4">
                    <button class="btn btn-facebook" [disabled]="isLoading" (click)="submitFacebookLogin()">Continue with Facebook</button>
                  </div>
                  <div class="col-sm-12">
                    <p class="text-center">Don't have account? <a [class.disabled]="isLoading" [routerLink]="['/register']">Sign Up</a></p>
                    <p class="text-center"><a [class.disabled]="isLoading" [routerLink]="['/forgot-password']">Forgot password?</a></p>
                  </div>
                </div><!-- end row-->
              </form>
            </div>
          </div><!-- end mobile form -->
        </div><!-- end tab-content -->
      </div><!-- end register-tab-->
    </div>
  </div>
</section>
