import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Store } from 'src/app/interface/menu.interface';
import { CartService } from 'src/app/service/cart.service';
import { CommonService } from 'src/app/service/common.service';
import { MenuService } from 'src/app/service/menu.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-store-popup',
  templateUrl: './store-popup.component.html',
  styleUrls: ['./store-popup.component.css']
})
export class StorePopupComponent implements OnInit {

  storeList: Store[];
  selectedStore: Store;
  searchText: string;
  first: Store;
  locationpermission: boolean = false;

  constructor(
    private menuService: MenuService,
    private userService: UserService,
    private commonService: CommonService,
    private cartService: CartService,
    public dialogRef: MatDialogRef<StorePopupComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
  ) { 
  }

  ngOnInit(): void {
    this.userService.selectedStore$
    .pipe(take(1))
    .subscribe((storeData) => {
      if(!storeData) {
        return;
      }
      this.selectedStore = storeData;
      this.first = storeData;
    });

    let thisfunc = this;
    navigator.permissions && navigator.permissions.query({name: 'geolocation'})
    .then(function(PermissionStatus) {
      if(PermissionStatus.state == 'granted') {
        thisfunc.locationpermission = true;
      } else {
        thisfunc.locationpermission = false;
      }
    })
    
    // .then(() => {
      this.userService.getUserLocationPayload().then((postData) => {
        this.menuService.getStore(postData).subscribe((response) => {
          if(!response.success) {
            return;
          }
          this.menuService.setStore(response.data);
          this.storeList = response.data;
          if(!this.selectedStore) {
            this.userService.setSelectedStore(response.data[0]);
          }
        });
      });
    // });
  }

  selectStore(store: Store): void {
    const cartItems = this.cartService.cartLength();
    if(cartItems > 0) {
      let dialogResponse = this.commonService.actionDialog('Alert', 'Your shopping cart will be emptied if you change your store selection', 'Continue');
      dialogResponse.subscribe(data => {
        if(data) {
          this.cartService.destroy();
          this.selectedStore = store;
        }
      });
      return;
    }
    this.selectedStore = store;
  }

  slug(str: string): string {
    return this.commonService.dashCase(str);
  }

  onClose(): void {
    this.dialogRef.close({ updated: this.checkSameStoreSelected() });
  }

  private checkSameStoreSelected(): boolean {
    let updated = false;
    if(this.first && this.selectedStore && this.first.id !== this.selectedStore.id) {
      updated = true;
      this.userService.setSelectedStore(this.selectedStore);
    }
    return updated;
  }
}
