<div class="page-content">
  <section class="promotion-banner">
    <div class="container-fluid">
      <div class="row title-row">
        <div class="col-sm-6 col-6 text-left">
          <div class="section-title">
            <h3>Promotions</h3>
          </div>
        </div>
        <div class="col-sm-6 col-6 text-right">
          <div class="section-view-all">
            <a [routerLink]="['/promotion']">View all</a>
          </div>
        </div>
      </div>

      <span *ngIf="!promotionPlaceholder">
        <owl-carousel [options]="promotionOption" [items]="promotionList" [carouselClasses]="['owl-theme', 'owl-banner']" #owlElement>
          <div class="item" *ngFor="let promo of promotionList">
            <a [routerLink]="['/promotion', slug(promo.name)]">
              <img class="promotion-img" style="width: 100%;" [src]="promo.image_web" [alt]="promo.name" [title]="promo.name">
              <!-- <div class="promotion-title">{{promo.name}}</div> -->
            </a>
          </div>
        </owl-carousel>
      </span>
      <span *ngIf="promotionPlaceholder">
        <div class="item">
          <img class="promotion-img" style="width: 100%;" src="assets/images/web_promo_placeholder_01-1.jpg">
        </div>
      </span>
    </div>
  </section>
  
  <!-- <section class="home-menu">
    <div class="container-fluid">
      <div class="row title-row">
        <div class="col-sm-6 col-6 text-left">
          <div class="section-title">
            <h3>Our Menu</h3>
          </div>
        </div>
        <div class="col-sm-6 col-6 text-right">
          <div class="section-view-all">
            <a [routerLink]="['/product']">View all</a>
          </div>
        </div>
      </div>
      <owl-carousel [options]="categoryOption" [items]="categoryList" [carouselClasses]="['owl-theme', 'menu-slider']">
        <div class="item category-item" *ngFor="let category of categoryList" #catItem [ngStyle]="{'height.px': catItem.offsetWidth }">
          <a href="https://www.sushiizu.com.au/menu" *ngIf="category.allow_order === '0'">
            <img class="category-img" [src]="category.image" [alt]="category.name" [title]="category.name">
            <div class="menu-title">{{category.name}}</div>
          </a>
          <a [routerLink]="['/product']" [state]="{ category: category.id }" *ngIf="category.allow_order === '1'">
            <img class="category-img" [src]="category.image" [alt]="category.name" [title]="category.name">
            <div class="menu-title">{{category.name}}</div>
          </a>
        </div>
      </owl-carousel>
    </div>
  </section> -->
  <section class="home-menu">
    <div class="container-fluid">
      <div class="row title-row">
        <div class="col-sm-6 col-6 text-left">
          <div class="section-title">
            <h3>Our Menu</h3>
          </div>
        </div>
        <div class="col-sm-6 col-6 text-right">
          <div class="section-view-all">
            <a [routerLink]="['/product']">View all</a>
          </div>
        </div>
      </div>
      <span id="category-carousel">
        <owl-carousel [options]="categoryOption" [items]="categoryList" [carouselClasses]="['owl-theme', 'menu-slider']">
          <div class="newcategory-wrap" *ngFor="let category of categoryList">
            <div class="newcategory-item">
              <a href="https://www.sushiizu.com.au/menu" *ngIf="category.allow_order === '0'">
                <div class="newcategory-title">{{category.name}}</div>
              </a>
              <a [routerLink]="['/product']" [state]="{ category: category.id }" *ngIf="category.allow_order === '1'">
                <div class="newcategory-title">{{category.name}}</div>
              </a>
            </div>
          </div>
        </owl-carousel>
      </span>
      <span id="category-mobile" *ngIf="categoryList">
        <mat-accordion class="category-accordion">
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="newcategory-item-mobile acive header">
                  <div class="newcategory-title-mobile">Click to view categories <mat-icon>expand_more</mat-icon></div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="newcategory-item-mobile" [class.active]="i == 0" *ngFor="let category of categoryList; let i = index">
              <a href="https://www.sushiizu.com.au/menu" class="w-100 h-100" *ngIf="category.allow_order === '0'">
                <div class="newcategory-title-mobile">{{category.name}}</div>
              </a>
              <a [routerLink]="['/product']" [state]="{ category: category.id }" class="w-100 h-100" *ngIf="category.allow_order === '1'">
                <div class="newcategory-title-mobile">{{category.name}}</div>
              </a>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <!-- selection -->
      </span>
    </div>
  </section>
  
  <section class="new-linup">
    <div class="container-fluid">
      <div class="row title-row">
        <div class="col-sm-6 col-6 text-left">
          <div class="section-title">
            <h3>New Line Up</h3>
          </div>
        </div>
        <div class="col-sm-6 col-6 text-right">
          <div class="section-view-all">
            <a [routerLink]="['/product']">View all</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div *ngFor="let product of newLineList" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6 product-wrap mb-30">
          <div class="box">
            <div class="product-img"><a role="button" (click)="selectProduct(product)"><img [src]="product.prod_image" alt="menu"></a></div>
            <div class="product-title"><a role="button" (click)="selectProduct(product)">{{product.prod_name}}</a></div>
            <div class="product-excerpt text-capitalize" [innerHTML]="product.prod_pack_type | sanitizeHtml"></div>
            <div class="product-price text-bottom-wrap">{{product.prod_price | currency}}</div>
          </div>
        </div>
      </div>
      <!-- ghost element -->
      <ng-template [ngIf]="!newLineList">
        <div class="row ghost-ui">
          <div *ngFor="let item of [0, 1, 2, 3, 4, 5]" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6 product-wrap mb-30">
            <div class="box">
              <div class="product-img shimmer"></div>
              <div class="product-title shimmer"></div>
              <div class="product-excerpt shimmer"></div>
              <div class="product-price shimmer"></div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </section>
  
  <section class="best-seller">
    <div class="container-fluid">
      <div class="row title-row">
        <div class="col-sm-6 col-6 text-left">
          <div class="section-title">
            <h3>Best Sellers</h3>
          </div>
        </div>
        <div class="col-sm-6 col-6 text-right">
          <div class="section-view-all">
            <a [routerLink]="['/product']">View all</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div *ngFor="let product of bestSellerList" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6 product-wrap mb-30">
          <div class="box">
            <div class="product-img"><a role="button" (click)="selectProduct(product)"><img [src]="product.prod_image" alt="menu"></a></div>
            <div class="product-title"><a role="button" (click)="selectProduct(product)">{{product.prod_name}}</a></div>
            <div class="product-excerpt text-capitalize" [innerHTML]="product.prod_pack_type | sanitizeHtml"></div>
            <div class="product-price text-bottom-wrap">{{product.prod_price | currency}}</div>
          </div>
        </div>
      </div>
      <!-- ghost element -->
      <ng-template [ngIf]="!bestSellerList">
        <div class="row ghost-ui">
          <div *ngFor="let item of [0, 1, 2, 3, 4, 5]" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6 product-wrap mb-30">
            <div class="box">
              <div class="product-img shimmer"></div>
              <div class="product-title shimmer"></div>
              <div class="product-excerpt shimmer"></div>
              <div class="product-price shimmer"></div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </section>
</div>
