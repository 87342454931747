<div class="page-content">
  <section class="product-detail" *ngIf="product">
    <div class="container-fluid">
      <div class="col-12 mb-4">
        <a class="text-underline primary-text" [routerLink]="['/product']"> << Back to Product Selection</a>
      </div>
      <div class="section-title">
        <h3>Product Details</h3>
      </div>
      <div class="row">
        <div class="col-md-4 px-4 text-center">
          <div *ngIf="TypeOf(product.prod_image) !== 'string'" class="product-image-wrap">
            <owl-carousel [options]="productOption" [carouselClasses]="['owl-theme']" #owlElement>
              <div class="item" *ngFor="let prodImg of product.prod_image">
                <img class="product-img" [src]="prodImg" [alt]="product.prod_name">
              </div>
            </owl-carousel>
          </div>
          <div class="product-img-wrap" *ngIf="TypeOf(product.prod_image) === 'string'">
            <img class="product-img" [src]="product.prod_image" [alt]="product.prod_name" style="max-width: 100%;">
          </div>
        </div>
        
        <div class="col-md-4 px-4">
          <div class="content mb-4">
            <div class="title mb-2">{{product.prod_name}}</div>
            <div class="mb-2">
              <div class="price font-weight-bold f18 primary-text">{{product.prod_price | currency}}</div>
              <div class="gst-inclusive f14">
                <span *ngIf="product.is_incl_gst">Incl GST {{product.prod_tax | currency}}</span>
              </div>
            </div>
            <div class="packtype font-weight-bold mb-2">{{product.prod_pack_type}}</div>
            <!-- <div class="availability mb-2">Availability:&nbsp;
              <span *ngIf="product.allow_purchase === '1'">In-Stock</span>
              <span class="text-alert" *ngIf="product.allow_purchase !== '1'">Out of stock</span>
            </div> -->
            <span *ngIf="product.prod_desc !== '' && product.prod_desc && product.prod_desc !== 'null'">
              <div class="mb-1" >Product Breakdown:</div>
              <div class="ingredient mb-4" [innerHTML]="product.prod_desc | sanitizeHtml"></div>
            </span>

            <div class="mt-3 mb-3">
              <div class="font-weight-medium text-underline">Ingredients</div>
              <div class="prod-toggle-desc" [innerHTML]="product.prod_ingredient | sanitizeHtml"></div>
            </div>
            <div class="mb-3">
              <div class="font-weight-medium text-underline">Nutritional Information</div>
              <div class="prod-toggle-desc" [innerHTML]="product.prod_nutrition | sanitizeHtml"></div>
            </div>
            <div class="mb-3">
              <div class="font-weight-medium text-underline">Allergens</div>
              <div class="prod-toggle-desc" [innerHTML]="product.prod_allergen | sanitizeHtml"></div>
            </div>

            <!-- <mat-tab-group animationDuration="0ms" disableRipple="true">
              <mat-tab label="Ingredients">
                <div class="toggle-content prod-toggle-desc" [innerHTML]="product.prod_ingredient | sanitizeHtml"></div>
              </mat-tab>
              <mat-tab label="Nutritional Information">
                <div class="toggle-content prod-toggle-desc" [innerHTML]="product.prod_nutrition | sanitizeHtml"></div>
              </mat-tab>
              <mat-tab label="Allergens">
                <div class="toggle-content prod-toggle-desc" [innerHTML]="product.prod_allergen | sanitizeHtml"></div>
              </mat-tab>
            </mat-tab-group> -->
          </div>
        </div>
        
        <div class="col-md-4 px-4">
          <div class="content">
            <div class="title border-bottom-line">Order Options</div>
            <div *ngIf="product.cat_option && product.cat_option.length > 0" class="configure-content">
              <div *ngFor="let configure of product.cat_option; let catIndex = index" class="product-option border-bottom-line mb-4">
                <div class="option-title font-weight-medium">
                  {{configure.cat_option_name}}
                </div>
                <div *ngIf="configure.cat_option_is_multi == '1' && configure.cat_option_max >= 1" class="mb-3">
                  <div class="f14 mb-3">(Max selection: {{configure.cat_option_max}})</div>
                  <div *ngFor="let option of configure.prod_option; let optionIndex = index" >
                    <mat-checkbox
                        [value]="option" 
                        [(ngModel)]="option.checked"
                        (ngModelChange)="onOptionCheck(option, catIndex)">
                        <span>{{option.option_name}} &nbsp;</span>
                        <span class="primary-text">
                          <span *ngIf="option.option_price > 0">+</span>{{option.option_price | currency}}
                        </span>
                    </mat-checkbox>
                  </div>
                </div>

                <div *ngIf="configure.cat_option_is_multi == '0' && configure.cat_option_max == '1'">
                  <mat-radio-group class="option-radio-group" >
                    <mat-radio-button 
                      #radioBtn
                      [value]="option" 
                      *ngFor="let option of configure.prod_option; let optionIndex = index" 
                      [checked]="option.checked" 
                      (click)="onOptionChange(optionIndex, catIndex, option.checked)">
                      <span>{{option.option_name}} &nbsp;</span>
                      <span class="primary-text">
                        <span *ngIf="option.option_price > 0">+</span>{{option.option_price | currency}}
                      </span>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                
              </div>
            </div>
            <div class="cart-content">
              <div class="total mb-3">
                <div class="font-weight-bold">Total</div>
                <div class="f18 font-weight-bold">{{total | currency}}</div>
              </div>
              <div class="quantity mb-3">
                <div class="font-weight-bold">Quantity</div>
                
                <div class="d-flex align-items-center">
                  <button mat-mini-fab color="primary" (click)="updateQuantity(-1)">
                    <i class="fa fa-minus"></i>
                  </button>
                  <div class="text-center mx-3">
                    <div class="item-quantity mx-80">{{quantity}}</div>
                  </div>
                  <button mat-mini-fab color="primary" (click)="updateQuantity(1)">
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="text-right mt-4">
                <button class="btn btn-add-to-cart mt-2" (click)="addToCart()">Add To Cart</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>