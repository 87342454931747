import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiResponse, Country } from '../interface/common.interface';
declare var FB: any;

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {

  loginApi: string = environment.apiBaseUrl + 'login';
  countryApi: string = environment.apiBaseUrl + 'country';
  registerApi: string = environment.apiBaseUrl + 'signup';
  forgotpasswordApi: string = environment.apiBaseUrl + 'forgotpassword';
  activationApi: string = environment.apiBaseUrl + 'activation';
  resentTacApi: string = environment.apiBaseUrl + 'resend';

  private countries: BehaviorSubject<Country[]> = new BehaviorSubject(null);
  countries$: Observable<Country[]> = this.countries.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  testConnection(): Observable<ApiResponse> {
    return this.http.post<ApiResponse>('https://sushiizu.it-paradise.com.au/app/test', {
      total_value: '100.00'
    });
  }

  login(userData): Observable<ApiResponse> {
    const payload = new HttpParams({fromObject: userData});
    return this.http.post<ApiResponse>(this.loginApi, payload);
  }

  register(userData): Observable<ApiResponse> {
    const payload = new HttpParams({fromObject: userData});
    return this.http.post<ApiResponse>(this.registerApi, payload);
  }

  getCountries(): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.countryApi, {});
  }

  setCountries(countryList): void {
    this.countries.next(countryList);
  }

  forgotPassword(emailOrMobile): Observable<ApiResponse> {
    const payload = new HttpParams({fromObject: emailOrMobile});
    return this.http.post<ApiResponse>(this.forgotpasswordApi, payload);
  }

  activateAccount(data): Observable<ApiResponse> {
    const payload = new HttpParams({fromObject: data});
    return this.http.post<ApiResponse>(this.activationApi, payload);
  }

  resendTacCode(data): Observable<ApiResponse> {
    const payload = new HttpParams({fromObject: data});
    return this.http.post<ApiResponse>(this.resentTacApi, payload);
  }

  // facebook
  initializeFacebookSdk(): void {
    (window as any).fbAsyncInit = function() {
      FB.init({
        appId : environment.facebookAppId,
        cookie : true,
        xfbml : true,
        version : 'v9.0'
      });
      FB.AppEvents.logPageView();
    };
    (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
     }(document, 'script', 'facebook-jssdk'));
  }

  getFacebookLogin(): Promise<any> {
    return new Promise((resolve) => {
      FB.login((response: any) => {
        resolve(response);
      }, {scope: 'email'});
    });
  }

  getFacebookUser(accessToken): Promise<any> {
    return new Promise((resolve) => {
      FB.api('/me', {
          "fields":"id, first_name, last_name, email, picture",
          "access_token": accessToken
        }, function(response: any) {
          resolve(response);
        }
      );    
    });
  }
}
