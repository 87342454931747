import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuPromotion, Store } from '../interface/menu.interface';
import { User } from '../interface/user.interface';
import { MenuService } from '../service/menu.service';
import { UserService } from '../service/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-promotion-detail',
  templateUrl: './promotion-detail.component.html',
  styleUrls: ['./promotion-detail.component.css']
})
export class PromotionDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  promotion: MenuPromotion;
  productName: string;
  selectedStore: Store;
  private unsubscribe$ = new Subject();

  constructor(
    private menuService: MenuService,
    private route: ActivatedRoute,
    private userService: UserService,
    private commonService: CommonService
  ) {
    this.commonService.updateTitle('Promotion');
    this.productName = this.route.snapshot.params.name;
    // this.userService.updateShowPromotion();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.userService.selectedStore$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.selectedStore = data;
        this.menuService.getPromotion(this.selectedStore?.id).subscribe((response) => {
          if(!response.success) {
            return;
          }
          this.menuService.setPromotion(response.data);
        });
      });
  
      this.menuService.promotions$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if(!data) {
          return;
        }
        this.promotion = data.filter(promo => this.commonService.dashCase(promo.name) === this.productName)[0];
      });
    });
  }

  onCollectVoucher(voucherId: string) {
    this.userService.user$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((userData: User) => {
      if(!userData || (userData && !userData.loggedin)) {
        this.commonService.alertDialog('Error!', 'Please login to claim the voucher', true);
        return;
      }
      if(userData.loggedin) {
        let postData = {
          item: voucherId,
          session: userData.session,
          deviceID: userData.deviceID
        }
        this.menuService.collectVoucher(postData).subscribe((response) => {
          if(!response.success) {
            if(response.message.toLowerCase().includes('invalid session') || response.message.toLowerCase().includes('session expired')) {
              this.commonService.alertDialog('Error!', response.message, true);
              return;
            }
            this.commonService.alertDialog('Error!', response.message, false);
            return;
          }
          this.commonService.alertDialog('Collected!', response.message);
        });
      } else {
        this.commonService.alertDialog('Error!', 'Please login to claim the voucher', true);
        // this.router.navigate(['login'], { state: { redirect: this.router.url } });
        return;
      }
    });
  }

  ngOnDestroy(): void {
    // this.userService.removeShowPromotion();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
