import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { PromotionVoucher } from 'src/app/interface/menu.interface';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-qr-voucher-popup',
  templateUrl: './qr-voucher-popup.component.html',
  styleUrls: ['./qr-voucher-popup.component.css']
})
export class QrVoucherPopupComponent implements OnInit {

  message: string;
  title: string;
  code: string;
  voucher: PromotionVoucher;
  selectedVoucher: PromotionVoucher;
  isLoading: boolean = true;
  isMatched: boolean = false;

  constructor(
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) private data,
    public dialogRef: MatDialogRef<QrVoucherPopupComponent>
  ) { 
    if(this.data) {
      this.title = this.data.title;
      this.message = this.data.message;
      this.code = this.data.code;
      this.voucher = this.data.voucher
    }
  }
  
  ngOnInit(): void {
    this.userService.selectedVoucher$
    .pipe(take(2))
    .subscribe((data) => {
      this.isLoading = false;
      this.selectedVoucher = data;
      if(this.selectedVoucher) {
        if(this.voucher.code === this.selectedVoucher.code) {
          this.isMatched = true;
        }
      }
    });
  }

  selectVoucher(voucher: PromotionVoucher): void {
    this.userService.selectVoucher(voucher);
    this.dialogRef.close(true);
  }
}
