<button class="close" mat-icon-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>Terms & Conditions</h1>
<div mat-dialog-content *ngIf="content">
  <div class="register-tab terms-tab">
    <ul class="nav nav-tabs mt-3">
      <li [class.active]="activeTab === 'eula'">
        <a class="show" [class.active]="activeTab === 'eula'" data-toggle="tab" (click)="setActiveTab('eula')">EULA</a>
      </li>
      <li [class.active]="activeTab === 'loyalty'">
        <a data-toggle="tab" [class.active]="activeTab === 'loyalty'" (click)="setActiveTab('loyalty')">Loyalty Programs</a>
      </li>
    </ul>
    <div class="tab-content mt-4">
      <div *ngIf="activeTab === 'eula'" [ngClass]="activeTab==='eula'?'show active':'fade'" id="eula" class="tab-pane">
        <!-- <pdf-viewer 
          [src]="content.TERM[0].EULAPolicy"
          [render-text]="true"
          [autoresize]="true"
          [show-borders]="true"
          [fit-to-page]='true'>
        </pdf-viewer> -->
        <!-- <object [data]="content.TERM[0].EULAPolicy + '#toolbar=0&navpanes=0' | safeUrl" type="application/pdf" width="100%" height="600px"></object> -->
        <object [data]="content.TERM[0].EULAPolicy | safeUrl" type="application/pdf" width="100%" height="500px"></object>
      </div><!-- end eula form -->

      <div *ngIf="activeTab === 'loyalty'" [ngClass]="activeTab==='loyalty'?'show active':'fade'" id="loyalty" class="tab-pane">
        <!-- <pdf-viewer 
          [src]="content.TERM[1].LoyaltyPolicy"
          [render-text]="true"
          [autoresize]="true"
          [show-borders]="true"
          [fit-to-page]='true'>
        </pdf-viewer> -->
        <object [data]="content.TERM[1].LoyaltyPolicy | safeUrl" type="application/pdf" width="100%" height="500px"></object>
      </div><!-- end loyalty form -->
    </div><!-- end tab-content -->
  </div><!-- end register-tab-->
</div>
