import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { MatSnackBar } from '@angular/material/snack-bar';
import { mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FCMessage } from '../interface/fcm.interface';

@Injectable({
  providedIn: 'root'
})
export class FcmessagingService {

  constructor(
    private afMessaging: AngularFireMessaging,
    private snackBar: MatSnackBar,
  ) { 
    this.afMessaging.usePublicVapidKey(environment.fcm.vapidKey);
    this.afMessaging.messages.subscribe((mesaging: AngularFireMessaging) => {
      mesaging.onMessage = mesaging.onMessage.bind(mesaging);
      mesaging.onTokenRefresh = mesaging.onTokenRefresh.bind(mesaging);
    });
  }

  // handles popup permission request & token
  requestTokenPermission(): Promise<string> {
    return new Promise((resolve) => {
      this.afMessaging.requestToken.subscribe(
        (token) => { 
          resolve(token); 
        }, (error) => { 
          console.error('requestToken error', error);
          resolve(null); 
        }
      )
    });
  }

  // popup permission request
  requestNotificationPermission(): Promise<boolean> {
    return new Promise((resolve) => {
      this.afMessaging.requestPermission.subscribe(
        () => {
          resolve(true);
        }, (error) => {
          console.error('requestPermission error',error);
          resolve(false);
        }
      )
    });
  }

  // get token if permission enabled
  getNotificationToken(): Promise<string> {
    return new Promise((resolve) => {
      this.afMessaging.getToken.subscribe(
        (token) => { 
          resolve(token); 
        }, (error) => { 
          console.error('getToken error', error);
          resolve(null); 
        }      
      )
    });
  }

  deleteNotificationToken(): void {
    this.afMessaging.getToken
    .pipe(mergeMap(token => this.afMessaging.deleteToken(token)))
    .subscribe(
      (token) => { 
        console.log('token deleted'); 
      },
    );
  }

  listenNotification(): void {
    this.afMessaging.messages.subscribe((message: FCMessage) => { 
      if(message && message.notification) {
        this.openSnackBar(message.notification.body, message.notification.title)
      }
    });
  }

  private openSnackBar(message: string, action: string) {
    const snackBarRef = this.snackBar.open(message, action, {
      duration: 0,
    });
  }
}
