import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CommonService } from 'src/app/service/common.service';
import { UserService } from 'src/app/service/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService,
    private commonService: CommonService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.userService.getProfile()
    .pipe(
      take(1),
      map((response) => {
        if(!response.success && (response.message.toLowerCase().includes('invalid session') || response.message.toLowerCase().includes('session expired'))) {
          this.router.navigate(['/login']);
          this.commonService.alertDialog('Error!', 'Session expired');
          this.userService.logout();
          return false;
        }
        return true;
      })
    );
  }
}
