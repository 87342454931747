<div class="page-content">
  <section class="order-success">
    <div class="container">
      <div class="section-title text-center mb-4">
        <h3>Order Successful</h3>
      </div>
      <div *ngIf="ordered" class="row justify-content-center">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="pb-2 mb-3">
            <div class="primary-text font-weight-bold f24">{{ordered.grand_total | currency}}</div>
            <div class="font-weight-medium">Order Successful!</div>
            <div class="font-weight-medium">{{ordered.orderdate | date:'d MMM, Y'}}</div>
            <div class="font-weight-medium">Order No: {{ordered.orderno}}</div>
            <div class="my-4">Thank you for your order. Your order has been sent to your selector store.</div>
            <div>
              <a [routerLink]="['/']" class="btn btn-white-round mb-3 mw-100">Go to Home</a>
              <a [routerLink]="['/dashboard/order/', ordered.orderno]" class="btn btn-primary-round mw-100">View Order</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
