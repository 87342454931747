<button class="close" mat-icon-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<div mat-dialog-content class="pt-4">
  <div class="text-center">
    <p class="font-weight-bold f18 mb-2">Welcome</p>
    <p class="mt-3">Login or create an account to skip the queue.</p>
  </div>
</div>

<div mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>Skip login</button>
  <button mat-raised-button color="primary" mat-dialog-close="true">Login Now</button>
</div>