import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/service/common.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-terms-popup',
  templateUrl: './terms-popup.component.html',
  styleUrls: ['./terms-popup.component.css']
})
export class TermsPopupComponent implements OnInit, AfterViewInit, OnDestroy {
  activeTab: string = 'eula';
  content: any;

  private unsubscribe$ = new Subject();

  constructor(
    private userService: UserService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
  }

  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.userService.content$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if(!data) {
          this.userService.getAppContent().subscribe((response) => {
            if(response.data[0]) {
              this.userService.storeContent(response.data[0]);
            }
          });
          return;
        }
        this.content = data;
      })
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
