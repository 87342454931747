import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { AuthenticateService } from '../service/authenticate.service';
import { UserService } from '../service/user.service';
import { Country } from '../interface/common.interface';
import { Router } from '@angular/router';
import { CommonService } from '../service/common.service';
import { FcmessagingService } from '../service/fcmessaging.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {

  form: FormGroup;
  errorMessage: string = null;
  showPassword: Boolean = false;
  activeTab: string = 'email';

  countryCtrl = new FormControl();
  filteredCountries: Observable<Country[]>;
  options: Country[];

  isLoading: boolean = false;
  deviceId: string;

  private unsubscribe$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticateService,
    private userService: UserService,
    private commonService: CommonService,
    private router: Router,
    private fcm: FcmessagingService
  ) {
    this.commonService.updateTitle('Login');
    this.deviceId = userService.getDeviceId();
    this.form = this.fb.group({
      type: [this.activeTab],
      facebookId: [''],
      mobileNo: [''],
      emailAddress: [''],
      password: [''],
      deviceID: [this.deviceId],
      notificationToken: [''],
      autologin: [false]
    });
  }

  ngOnInit(): void {
    this.authService.initializeFacebookSdk();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.userService.user$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        if(user && user.notificationToken) {
          this.form.patchValue({ notificationToken: user.notificationToken });
          return;
        }
        this.patchNotificationToken();
      });

      // this.authService.countries$
      // .pipe(takeUntil(this.unsubscribe$))
      // .subscribe((data) => {
      //   if(!data) {
      //     this.authService.getCountries().subscribe((response) => {
      //       this.options = response.data;
      //       this.authService.setCountries(response.data);
      //     });
      //     return;
      //   }
      //   this.options = data;
      // });

      // // autocomplete countries input
      // this.filteredCountries = this.countryCtrl.valueChanges
      // .pipe(
      //   startWith(''),
      //   map(country => {
      //     if(country) {
      //       return this.filterCountryQuery(country)
      //     }
      //     if(this.options) {
      //       return this.options.slice();
      //     }
      //   })
      // );
    }, 0);
  }

  // private filterCountryQuery(value: string): Country[] {
  //   const filterValue = value.toLowerCase();
  //   return this.options.filter(country => country.phonecode.toLowerCase().indexOf(filterValue) === 0);
  // }

  private backToPreviousPage(): void {
    const { redirect } = window.history.state;
    this.router.navigateByUrl(redirect || '/');
  }
  
  onClickShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
    this.errorMessage = null;
    this.form.patchValue({ 
      type: tab,
      mobileNo: '',
      emailAddress: '',
      password: '',
    });
  }

  submitLogin(): void {
    this.isLoading = true;
    let value = this.form.value;

    this.authService.login(value).subscribe((response) => {
      this.isLoading = false;
      if(!response.success) {
        this.errorMessage = response.message;
        return;
      }
      this.errorMessage = null;
      this.userService.updateUser(response.data[0]);
      this.userService.updateUserStatus(true);
      this.userService.clearPopupConfigs();
      this.backToPreviousPage();
    });
  }

  async submitFacebookLogin() {
    const loginResponse = await this.authService.getFacebookLogin();
    if(loginResponse && loginResponse.authResponse) {
      this.form.patchValue({
        type: 'facebook',
        facebookId: loginResponse.authResponse.userID
      });

      const value = this.form.value;
      this.authService.login(value).subscribe((response) => {
        if(!response.success) {
          this.commonService.alertDialog('Error!', response.message);
          return;
        }
        this.userService.updateUser(response.data[0]);
        this.userService.updateUserStatus(true);
        this.userService.clearPopupConfigs();
        this.backToPreviousPage();
      });
    }
  }

  private patchNotificationToken(): void {
    this.fcm.getNotificationToken().then((token) => {
      this.form.patchValue({ notificationToken: token });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
