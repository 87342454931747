import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const Authcode  = '744e0c91c58d954ba40e869d55d0c47ee2749b5172d942158fca547812804307';
    
    return next.handle(
      request.clone({ 
        setHeaders: { Authcode } 
      })
    );
  }
}