import { Component, OnInit } from '@angular/core';
import { AuthenticateService } from '../service/authenticate.service';
import { CommonService } from '../service/common.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  input: string;
  errorRequired: Boolean = false;
  errorMessage: string;

  constructor(
    private authService: AuthenticateService,
    private commonService: CommonService,
    private userService: UserService
  ) { 
    this.commonService.updateTitle('Forgot Password');
  }

  ngOnInit(): void {
  }

  submit() {
    if(!this.input || this.input === '') {
      this.errorRequired = true;
      return;
    }

    let data = {
      "deviceID" : this.userService.getDeviceId(),
      "input": this.input
    }

    this.authService.forgotPassword(data).subscribe((response:any) => {
      if(!response.success) {
        this.errorMessage = response.message;
        return;
      }
      this.commonService.alertDialog('Done!', response.message);
      // alert(response.message);
    });
  }
}
