import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Store } from 'src/app/interface/menu.interface';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-store-confirmation-popup',
  templateUrl: './store-confirmation-popup.component.html',
  styleUrls: ['./store-confirmation-popup.component.css']
})
export class StoreConfirmationPopupComponent implements OnInit {

  selectedStore: Store;
  locationpermission: boolean = false;

  constructor(
    private userService: UserService
  ) { 
    this.userService.selectedStore$
    .pipe(take(2))
    .subscribe((data) => {
      if(!data) {
        return;
      }
      this.selectedStore = data;
    });

    let thisfunc = this;
    navigator.permissions && navigator.permissions.query({name: 'geolocation'})
    .then(function(PermissionStatus) {
      if (PermissionStatus.state == 'granted') {
        thisfunc.locationpermission = true;
      } else {
        thisfunc.locationpermission = false;
      }
    });
  }

  ngOnInit(): void {
  }

}
