import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryOption, MenuProduct, ProductOption } from '../interface/menu.interface';
import { MenuService } from '../service/menu.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CartService } from '../service/cart.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '../service/common.service';
import { CartItem, CartOption } from '../interface/cart.interface';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit, OnDestroy {

  productOption: any = {
    loop: true,
    autoplay: true,
    dots: true,
    nav: false,
    items: 1,
    dotsEach: true,
  }
  
  product: MenuProduct;
  productName: string;
  total: number;
  options: CartOption[] = [];
  quantity: number = 1;
  optionPrice: number;

  private unsubscribe$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private menuService: MenuService,
    private cartService: CartService,
    private snackBar: MatSnackBar,
    private commonService: CommonService
  ) { 
    this.commonService.updateTitle('Product');
    this.productName = this.route.snapshot.params.name;
  }

  ngOnInit(): void {
    this.getProduct();
  }

  TypeOf(variable: any): string {
    return typeof(variable);
  }

  private getProduct(): void {
    if(!this.productName) {
      return;
    }
    // get selected product else navigate back to product page
    this.menuService.selectedProduct$
    .pipe(take(1))
    .subscribe((data) => {
      if(!data) {
        this.router.navigate(['product']);
        return;
      }
      if(this.commonService.dashCase(data.prod_name) !== this.productName) {
        this.router.navigate(['product']);
        return;
      }
      this.product = data;
      this.resetCheckedOptionRadio();
      this.updateTotalPrice();
    });
  }

  addToCart(): void {
    const success = this.atLeastOneOption();
    if(!success) {
      return;
    }
    // if(this.product.allow_purchase !== '1') {
    //   this.commonService.alertDialog('Error!', 'Out of stock');
    //   return;
    // }
    const finalProduct: CartItem = {
      prod_id: this.product.prod_id,
      price: this.product.prod_price,
      name: this.product.prod_name,
      prod_image: this.product.prod_image,
      quantity: this.quantity,
      remark: '',
      option: this.options.map(({cat_id, ...keepAttrs}) => keepAttrs),
      total: this.total,
    }
    this.cartService.handleCartItem(finalProduct);
    this.openSnackBar(`${finalProduct.name} added to cart`, 'Cart');
    this.router.navigate(['product']);
  }

  private openSnackBar(message: string, action: string): void {
    const snackBarRef = this.snackBar.open(message, action, {
      duration: 2000,
    });
    snackBarRef.onAction().subscribe(() => {
      this.router.navigate(['checkout']);
    });
  }

  private resetCheckedOptionRadio(): void {
    this.product.cat_option.map((catOption) => {
      catOption.prod_option.map((option) => {
        option.checked = false;
      });
    });
  }

  private checkedOptionRadio(index: number, optionId: number, check: boolean): void {
    this.product.cat_option[index].prod_option.map((option) => {
      option.checked = false;
      if(option.option_id === optionId) {
        option.checked = check;
      }
    });
  }

  onOptionChange(optionIndex: number, catIndex: number, currentCheck: boolean): void {
    setTimeout(() => {
      let newOptionList = [];
      const catOption = this.product.cat_option[catIndex];
      const updateOption = catOption.prod_option[optionIndex];
      this.checkedOptionRadio(catIndex, updateOption.option_id, !currentCheck);

      if(updateOption.checked) {
        let isNew = true;
        this.options.forEach((option) => {
          if(option.cat_id == catOption.cat_option_id) {
              isNew = false;
              newOptionList.push({
              "cat_id": catOption.cat_option_id,
              "option_id": updateOption.option_id,
              "option_price": updateOption.option_price,
              "option_name": updateOption.option_name
            });
          } else {
            newOptionList.push(option);
          }
        });
        if(isNew) {
          newOptionList.push({
            "cat_id": catOption.cat_option_id,
            "option_id": updateOption.option_id,
            "option_price": updateOption.option_price,
            "option_name": updateOption.option_name
          });
        }
      } else {
        this.options.forEach((option) => {
          if(option.cat_id !== catOption.cat_option_id) {
            newOptionList.push(option);
          }
        });
      }
      this.options = newOptionList;
      this.updateTotalPrice();
    });
  }

  onOptionCheck(checkOption: ProductOption, catIndex: number): void {
    setTimeout(() => {
      const catOption = this.product.cat_option[catIndex];
      let catOptionLength = this.options.filter((option) => option.cat_id == catOption.cat_option_id).length;
      if(checkOption.checked) {
        if(catOptionLength >= catOption.cat_option_max) {
          this.product.cat_option[catIndex].prod_option.map((option) => {
            if(option.option_id === checkOption.option_id) {
              option.checked = false;
            }
          });
          return;
        }
        this.options.push({
          "cat_id": catOption.cat_option_id,
          "option_id": checkOption.option_id,
          "option_price": checkOption.option_price,
          "option_name": checkOption.option_name
        });
      } else {
        this.options = this.options.filter((option) => option.option_id !== checkOption.option_id);
      }
      this.updateTotalPrice();
    });
  }

  private atLeastOneOption(): boolean {
    const catOptions = this.product.cat_option.map(({ cat_option_id }) => cat_option_id );
    const selectedCatOptions = [...new Set(this.options.map(({ cat_id }) => cat_id ))]

    let difference = catOptions
        .filter(x => !selectedCatOptions.includes(x))
        .concat(selectedCatOptions.filter(x => !catOptions.includes(x)));

    if(difference.length > 0) {
      this.commonService.alertDialog('Alert', 'You have an unselected configuration. Please ensure you have selected at least 1 item for each option category to proceed');
      return false;
    }
    return true;
  }

  updateQuantity(qty: number): void {
    this.quantity += qty;
    if(!this.quantity || this.quantity === 0) {
      this.quantity = 1;
    }
    this.updateTotalPrice();
  }

  private updateTotalPrice(): void {
    const item: CartItem = {
      option: this.options,
      prod_id: this.product.prod_id,
      price: this.product.prod_price,
      name: this.product.prod_name,
      prod_image: this.product.prod_image,
      quantity: this.quantity,
      remark: '',
    }
    this.total = this.cartService.calculateItemPrice(item);
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
